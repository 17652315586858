<template>
  <BaseModal
    ref="baseModal"
    :id="id"
    class="from-quicklist"
    :heading="$t('Add from Favorites')"
    :on-confirm="onConfirm"
    :confirm-text="$t('Add Selected')"
    :disable-confirm-button="assetSelection.length < 1"
    :focus-trap-active="!nestedModalIsOpen"
    :hide-header-cancel-button="true"
    header-margin="1x"
    footer-margin="2x"
    :initial-focus="() => (previousFocus || $refs.baseModal?.$refs?.cancelButton)"
    :on-close="cancel"
  >
    <LoadingIndicator
      v-if="loading"
      class="from-quicklist__loading"
      small
      transparent
    />
    <div
      class="from-quicklist__paging-message"
      v-if="!loading && paging.page >= 0"
    >
      <p
        v-if="paging.total_pages === 0"
        class="from-quicklist__pagination-message"
      >
        {{ $t('No results.') }}
      </p>
      <p
        v-else
        class="from-quicklist__pagination-message"
      >
        {{ $t('Viewing page %(page)s of %(total_pages)s', {
          page: paging.page,
          total_pages: paging.total_pages,
        }) }}
      </p>
    </div>
    <div
      class="from-quicklist__list-wrapper"
      v-if="!loading"
    >
      <ul class="from-quicklist__list comet-cards">
        <li
          v-for="asset in results"
          :key="asset.id"
          class="nebula-card nebula-card--select"
          :class="{ 'nebula-card--active': isSelected(asset.id) }"
        >
          <input
            ref="quickListItems"
            type="checkbox"
            class="nebula-screenreader-only"
            :checked="isSelected(asset.id)"
            :value="asset.id"
            :title="asset.title"
            :id="`from-quicklist-modal-asset-${asset.id}`"
            @change="onSelectAsset($event, asset)"
          >
          <label
            class="from-quicklist__result-label"
            :for="`from-quicklist-modal-asset-${asset.id}`"
          >
            <AssetCard
              :asset="asset"
              :show-description="false"
              @set-previous-focus="previousFocus = $event"
              @remove-from-quicklist="removeFromQuickList"
            />
            <div class="from-quicklist__checked-icon">
              <NebulaIcon
                v-if="isSelected(asset.id)"
                symbol-id="circle-check--filled"
                size="l"
              />
            </div>
          </label>
        </li>
        <!-- Intentional flex spacers -->
        <li class="nebula-card nebula-card--invisible" />
        <li class="nebula-card nebula-card--invisible" />
        <li class="nebula-card nebula-card--invisible" />
      </ul>
    </div>
    <StudioPagination
      class="from-quicklist__pagination"
      v-if="!loading && paging.page"
      :current-page="paging.page"
      :total-pages="paging.total_pages"
      use-button
      @selectPage="onSelectPage"
    />
  </BaseModal>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AssetCard from '@/components/ui-elements/AssetCard.vue';
import StudioPagination from '@/components/navigation/StudioPagination.vue';
import assetSelectionModal from '@/mixins/assetSelectionModal';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
} from '@/stores';

export default {
  name: 'FromQuicklist',
  components: {
    BaseModal,
    LoadingIndicator,
    AssetCard,
    StudioPagination,
    NebulaIcon,
  },
  mixins: [assetSelectionModal], // handles selecting assets and adding to the canvas
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
  },
  data() {
    return {
      results: [],
      loading: false,
      paging: {},
      selectedPage: null,
      // for keeping track of where to restore focus to when a submodal is closed
      previousFocus: null,
    };
  },
  computed: {
    ...mapState(useModalStore, [
      'modals',
      'myContentModalOptions',
    ]),
    nestedModalIsOpen() {
      return this.modals.find((modal) => modal.type === 'AssetShareModal')
        || get(this, 'myContentModalOptions.config.isActive');
    },
  },
  mounted() {
    this.getQuicklist();
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    cancel() {
      // If the share modal or My Content modal is open, don't close
      // this modal
      if (!this.nestedModalIsOpen) {
        this[types.CLOSE_MODAL]({ id: this.id });
      }
    },
    onSelectPage(page) {
      this.selectedPage = page;
      this.getQuicklist();
    },
    async getQuicklist() {
      this.loading = true;
      const path = `/mycontent/assets?page=${this.selectedPage || 1}&per_page=12&root_folder_type=4&root_owner=user&template=tea_list`;
      const result = await this.$deApi.get(path);
      this.results = result.data.mycontent;
      this.paging = result.data.paging;
      this.loading = false;
      this.$nextTick(() => {
        // Focus the first input in the modal
        const firstQuickListItem = this.$refs.quickListItems && this.$refs.quickListItems[0];
        if (firstQuickListItem) firstQuickListItem.focus();
      });
    },
    removeFromQuickList(assetId) {
      this.results = this.results.filter((asset) => asset.id !== assetId);
    },
  },
};
</script>

<style lang="stylus">
.from-quicklist {
  .comet-modal {
    width: 100%;
  }

  .comet-modal__inner {
    display: flex;
    flex-direction: column;
    height: "calc(100vh - %s)" % $nebula-space-4x; // match the outer max-height
  }

  .comet-long-form-text {
    height: auto;
    overflow: visible;

    h3 {
      text-align: center;
    }
  }

  &__loading {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  &__list {
    padding-left: $nebula-space-2x;

    > .nebula-card {
      box-shadow: none;
      margin-inline-end: $nebula-space-2x;
    }
  }

  &__list-wrapper {
    height: 100%;
    margin: $nebula-space-2x $nebula-space-2x * -1 0;
    overflow: auto;
    padding: $nebula-space-2x 0;

    .from-quicklist__checked-icon {
      fill: $nebula-color-white;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .comet-card__card-anchor {
      cursor: pointer;
    }

    .nebula-card {
      overflow: visible;
      position: relative;

      &.nebula-card--active {
        box-shadow: 0 0 0 2px $nebula-color-interactive-blue-300;
      }

      &--invisible {
        box-shadow: none;
        opacity: 0;
      }
    }

    .from-quicklist__checked-icon {
      padding-top: 56.25%; // position in the center of the 16/9 thumbnail
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      &:after {
        background-color: $nebula-color-white;
        border-radius: 50%;
        bottom: 2px;
        content: '';
        display: block;
        left: 5%;
        padding-top: 90%;
        position: absolute;
        width: 90%;
      }

      svg {
        display: block;
        fill: $nebula-color-interactive-blue-300;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__result-label {
    cursor: pointer;
    flex-grow: 1;
  }

  &__pagination {
    display: flex;
    justify-content: center;

    ul {
      margin-bottom: 0;
    }
  }
}
</style>
