<template>
  <div
    class="loading-indicator"
    :class="{
      'loading-indicator--absolute': absolute,
      'loading-indicator--small': small,
      'loading-indicator--transparent': transparent,
      'loading-indicator--translucent': translucent,
      'loading-indicator--contain-within': containWithin,
    }"
  >
    <div class="comet-spinner comet-spinner--medium comet-spinner--on-light">
      <!-- eslint-disable -->
      <svg class="comet-spinner__icon" aria-labelledby="title" viewBox="-10 -10 220 220">
          <title>{{ $t('Loading...') }}</title>
          <defs>
              <linearGradient id="spinner-color-1"
              gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" :stop-color="color" stop-opacity="0" />
                  <stop offset="100%" :stop-color="color" stop-opacity=".2" />
              </linearGradient>
              <linearGradient id="spinner-color-2"
              gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" :stop-color="color" stop-opacity=".2" />
                  <stop offset="100%" :stop-color="color" stop-opacity=".4" />
              </linearGradient>
              <linearGradient id="spinner-color-3"
              gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1">
                  <stop offset="0%" :stop-color="color" stop-opacity=".4" />
                  <stop offset="100%" :stop-color="color" stop-opacity=".6" />
              </linearGradient>
              <linearGradient id="spinner-color-4"
              gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
                  <stop offset="0%" :stop-color="color" stop-opacity=".6" />
                  <stop offset="100%" :stop-color="color" stop-opacity=".8" />
              </linearGradient>
              <linearGradient id="spinner-color-5"
              gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
                  <stop offset="0%" :stop-color="color" stop-opacity=".8" />
                  <stop offset="100%" :stop-color="color" stop-opacity="1" />
              </linearGradient>
              <linearGradient id="spinner-color-6"
              gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
                  <stop offset="0%" :stop-color="color" stop-opacity="1" />
                  <stop offset="100%" :stop-color="color" stop-opacity="1" />
              </linearGradient>
          </defs>
          <circle :fill="color" cx="45%" cy="0" r="7.5" />
          <g fill="none" stroke-width="20" transform="translate(100,100)">
              <path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#spinner-color-1)" />
              <path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#spinner-color-2)" />
              <path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#spinner-color-3)" />
              <path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#spinner-color-4)" />
              <path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#spinner-color-5)" />
              <path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#spinner-color-6)" />
          </g>
      </svg>
      <!-- eslint-enable -->
      <p
        v-if="text"
        class="loading-indicator__text"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
import { tokens as cometTokens } from '@discoveryedu/comet-core/data/tokens.json';

export default {
  name: 'LoadingIndicator',
  props: {
    absolute: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    translucent: {
      type: Boolean,
      default: false,
    },
    containWithin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      if (this.transparent || this.translucent) {
        return cometTokens.color.neutral['55'];
      }
      return cometTokens.color.neutral['90'];
    },
  },
};
</script>

<style lang="stylus">
.loading-indicator {
  align-items: center;
  background-color: $comet-background-color-dark;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 550;

  &--contain-within {
    .comet-spinner {
      margin: auto;
      padding: $nebula-space-half;
    }
  }

  &--absolute { // .loading-indicator--absolute
    position: absolute;
  }

  &--small { // .loading-indicator--small
    align-items: stretch;
    flex: 1;
    position: relative;
    width: 100%;
  }

  &--transparent { // .loading-indicator--transparent
    background-color: transparent;

    .loading-indicator__text {
      color: $comet-color-neutral-55;
    }

  }

  &--translucent { // .loading-indicator--transparent
    background: rgba($comet-color-neutral-25, .95);

    .loading-indicator__text {
      color: $nebula-color-white;
    }

  }

  &__text {
    color: $nebula-color-white;
    font-size: $nebula-font-size-body-1;
    margin: $nebula-space-2x 0 0;
  }
}
</style>
