<template>
  <NebulaChannelCard
    v-if="asset.type.name === 'Channel'"
    :asset="asset"
    element="div"
    :target="'_blank'"
    :use-link="false"
  >
    <!-- This component is used in the SearchAssets and FromQuicklist modals -->
    <template #actionsMenu>
      <!-- These props and listeners come from the actionMenu mixin -->
      <ActionMenu
        v-bind="actionMenuProps"
        @quicklist:remove="onRemoveFromQuicklist"
        @quicklist:add="onAddToQuicklist"
        @subscribe="subscribe"
        @unsubscribe="unsubscribe"
        @share="openShareModal"
        @save="openMyContentModal"
      />
    </template>
  </NebulaChannelCard>

  <NebulaCard
    v-else
    :asset="asset"
    element="div"
  >
    <!--
      This is a little different from the NebulaAssetCard since it doesn't include
      links, so we're making a custom card using all the pieces that make up
      the NebulaAssetCard. If Nebula adds a "choose" card without links, we can
      update this
    -->
    <template #thumbnail>
      <NebulaCardThumbnail
        :asset-id="asset.id"
        :asset-type="assetTypeFriendly"
        :duration="formattedDuration"
        :title="assetTitle"
      />
    </template>
    <template #content>
      <NebulaCardHeader
        :asset-type="assetTypeFriendly"
      >
        <template #actionsMenu>
          <!-- These props and listeners come from the actionMenu mixin -->
          <ActionMenu
            v-bind="actionMenuProps"
            @quicklist:remove="onRemoveFromQuicklist"
            @quicklist:add="onAddToQuicklist"
            @share="openShareModal"
            @save="openMyContentModal"
          />
        </template>
      </NebulaCardHeader>
      <NebulaCardTitle
        :asset-id="asset.id"
        :title="assetTitle"
      />
      <NebulaCardDescription
        :text="asset.description"
      />
      <NebulaCardMetadata
        :metadata="asset.info_pills"
      />
      <NebulaCardLogos
        v-if="cardHasLogos"
        :asset="asset"
      />
    </template>
  </NebulaCard>
</template>

<script>
import {
  NebulaCard,
  NebulaCardDescription,
  NebulaCardHeader,
  NebulaCardMetadata,
  NebulaCardLogos,
  NebulaCardThumbnail,
  NebulaCardTitle,
  NebulaChannelCard,
} from '@discoveryedu/nebula-components';
import { ActionMenu } from '@discoveryedu/platform-components';
import { secondsToDuration } from '@/lib/utils';
import actionMenu from '@/mixins/actionMenu';

export default {
  name: 'AssetCard',
  components: {
    ActionMenu,
    NebulaCard,
    NebulaCardDescription,
    NebulaCardHeader,
    NebulaCardMetadata,
    NebulaCardLogos,
    NebulaCardThumbnail,
    NebulaCardTitle,
    NebulaChannelCard,
  },
  mixins: [actionMenu],
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    assetTitle() {
      return this.asset.title || this.asset.name || '';
    },
    assetTypeFriendly() {
      return this.asset?.type?.name;
    },
    cardHasLogos() {
      // Sometimes this.asset.de_original_partner_logo is an object
      // without any url inside. Make sure we don't try to show
      // a logo for that case
      return this.asset.de_original_partner_logo?.url
        || (this.asset.logos?.[0] && this.asset.logos[0].file_path);
    },
    formattedDuration() {
      return this.asset.duration && secondsToDuration(this.asset.duration);
    },
  },
};
</script>

<style lang="stylus">
.nebula-card--select {
  .nebula-card {
    margin-bottom: 0;
    height: 100%;
  }

  &:hover, .comet-button__input:focus + label {
    .nebula-card__title {
      color: $nebula-color-interactive-blue-400;
    }
  }
}
</style>
