<template>
  <NebulaModal
    v-if="true"
    size="s"
    @close-modal="close"
  >
    <template #header>
      <h3>
        {{ $t('Remove All Items') }}
      </h3>
    </template>
    <template #content>
      <NebulaBlockMessage
        v-if="error"
        color="red"
        :header="$t('Error')"
        icon="warning"
      >
        {{ error }}
      </NebulaBlockMessage>
      <p>
        {{ $t('Are you sure you want to remove all items in your Favorites?') }}
      </p>
    </template>
    <template #actionsRow>
      <div class="nebula-modal__actions-container">
        <NebulaButton
          :text="$t('Cancel')"
          type="flat"
          size="m"
          @click="close"
        />
        <NebulaButton
          id="remove-all-quicklist-items"
          :text="$t('Remove All')"
          size="m"
          is-submit
          :is-disabled="loading || !!error"
          @click="removeItemsFromQuicklist"
        />
      </div>
    </template>
  </NebulaModal>
</template>

<script>
import { NebulaBlockMessage, NebulaButton, NebulaModal } from '@discoveryedu/nebula-components';
import { get } from 'lodash-es';
import { mapActions } from 'pinia';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
  useQuicklistStore,
} from '@/stores';

export default {
  name: 'RemoveAllQuicklistItemsModal',
  components: {
    NebulaBlockMessage,
    NebulaButton,
    NebulaModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useQuicklistStore, [
      types.REMOVE_ALL_FROM_QUICKLIST,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    removeItemsFromQuicklist() {
      this.error = null;
      this.loading = true;
      this[types.REMOVE_ALL_FROM_QUICKLIST]()
        .then(() => {
          // A confirmation app message is shown in the action so
          // there's nothing else to do here
          this.options.onConfirm();
          this[types.CLOSE_MODAL]({ id: this.id });
        })
        .catch((e) => {
          this.error = get(e, 'response.data.meta.message', e.message);
          this.loading = false;
        });
    },
  },
};
</script>
