<template>
  <button
    class="k5-zoom-button"
    :class="`k5-zoom-button--${size}`"
    :aria-label="$t('Open larger view')"
    @click="$emit('click', $event)"
    @keyup="$emit('keyup', $event)"
  >
    <ZoomIcon />
  </button>
</template>

<script>
import ZoomIcon from '@/components/ui-elements/texas-icons/ZoomIcon.vue';

export default {
  name: 'K5ZoomButton',
  components: {
    ZoomIcon,
  },
  props: {
    size: {
      type: String,
      default: 'm', // either 's' or 'm'
    },
  },
  emits: [
    'click',
    'keyup',
  ],
};
</script>

<style lang="stylus">
.k5-zoom-button {
  align-items: center;
  background-color: $nebula-color-white;
  border-radius: 10px;
  border-width: 0;
  box-shadow: 0px 3.5px 24px 4.5px rgba(0, 0, 0, 0.1);
  display: flex;

  justify-content: center;
  overflow: hidden;
  &--s {
    height: $nebula-space-3x;
    padding: $nebula-space-half;
    width: $nebula-space-3x;
  }

  &--m {
    height: $nebula-space-4x;
    padding: 6px;
    width: $nebula-space-4x;
  }

  &:hover, &:focus {
    background-color: $nebula-color-platform-interactive-100;
  }

  &:active {
    background-color: $nebula-color-platform-interactive-200;
  }
}
</style>
