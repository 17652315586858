<template>
  <BaseModal
    class="tei-instructions-card-modal"
    :class="options.content || 'tei-instructions-card-modal--no-content'"
    :id="id"
    :is-wide="false"
    header-margin="1x"
    heading=" "
    hide-footer
  >
    <!-- 'left' position is used here to show the prompt image below the text -->
    <TEIInstructionsCard
      class="tei-instructions-card-modal__card"
      :content="options.content"
      :image-alt="options.imageAlt"
      :image-url="options.imageUrl"
      position="left"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';
import TEIInstructionsCard from '@/components/teis/TEIInstructionsCard.vue';

export default {
  name: 'TEIInstructionsCardModal',
  components: {
    BaseModal,
    TEIInstructionsCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="stylus">
.tei-instructions-card-modal {
  &__card {
    max-height: calc(100vh - 200px);
  }

  .comet-modal__inner {
    max-width: 900px;
    min-width: 500px;
    width: calc(100vw - 500px);
  }

  .tei-instructions-card--left {
    border-radius: 0;
    height: auto;
    padding: 0;
    width: auto;
  }

  .tei-instructions-card__zoom-button {
    display: none;
  }

  .tei-instructions-card__prompt-image-wrapper {
    width: 100%;
  }

  .tei-instructions-card__prompt-image {
    margin: 0 auto;
    max-height: calc(100vh - 200px);
    max-width: 100%;
  }

  &--no-content .tei-instructions-card__flex-spacer {
    display: none;
  }
}
</style>
