<template>
  <div
    id="app"
    class="notranslate"
  >
    <TopNav v-if="!isEmbeddedView && !isPrintPreview && !isHomeRoute" />
    <div
      class="page-content"
      :class="{
        'page-content--embedded': isEmbeddedView,
        'page-content--editor': !isHomeRoute,
        'page-content--print-preview': isPrintPreview,
        'page-content--home': isHomeRoute,
        'page-content--editor__extended': isEditingRestricted && pageIsFullSlideTEI,
      }"
    >
      <LoadingIndicator v-if="loading && !isPrintPreview" />
      <ErrorMessage
        v-if="error"
        :class="{ 'error-message--embedded': isEmbeddedView }"
      />
      <!--
        This is wrapped in a div to prevent Vue from re-rendering the RouterView
        when we toggle fullscreen, since that causes it to immediately exit
      -->
      <div>
        <AppMessageManager v-if="!editorIsFullscreen" />
      </div>
      <RouterView v-if="userCanAccessStudio || allowPublicAccess" />
      <StudioToast />
      <ModalManager v-if="!editorIsFullscreen" />
      <HelpButton
        v-if="enableHelpButton"
        :vertical-spacing-px="isCanvas ? 45 : 15"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { get } from 'lodash-es';
import { defineAsyncComponent } from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import ModalManager from '@/components/ModalManager.vue';
import StudioToast from '@/components/StudioToast.vue';
import AppMessageManager from '@/components/messages/AppMessageManager.vue';
import HelpButton from '@/components/HelpButton.vue';
import editorPaste from '@/mixins/editorPaste';
import * as types from '@/lib/constants/store';
import { bus } from '@/lib/eventBus';
import { getLocalData } from '@/lib/localData';
import {
  useAppStore,
  useEditorStore,
  useErrorStore,
  useInactivityStore,
  useUserStore,
  useAssessmentStore,
} from '@/stores';
import {
  PRODUCT_SETTING_HAS_SUPPORT_CHAT,
} from '@/lib/constants';

const TopNav = defineAsyncComponent(() => import('@/components/navigation/TopNav.vue'));
const ErrorMessage = defineAsyncComponent(() => import('@/components/errors/ErrorMessage.vue'));

export default {
  name: 'StudioApp',
  components: {
    AppMessageManager,
    ErrorMessage,
    LoadingIndicator,
    ModalManager,
    StudioToast,
    TopNav,
    HelpButton,
  },
  mixins: [editorPaste],
  methods: {
    ...mapActions(useAppStore, [
      types.INIT,
    ]),
    ...mapActions(useErrorStore, [
      types.SET_ERROR,
    ]),
    ...mapActions(useInactivityStore, [
      types.START_AUTH_CHECK,
      types.END_AUTH_CHECK,
    ]),
    onBodyPaste(e) {
      if (this.$route?.name === 'edit') {
        this.onPaste(e);
      }
    },
  },
  computed: {
    ...mapState(useUserStore, [
      'user',
      'userCanAccessStudio',
      'productSettings',
      'userIsStudent',
    ]),
    ...mapState(useAppStore, [
      'initialized',
      'loading',
    ]),
    ...mapState(useEditorStore, [
      'editorIsFullscreen',
      'isEditingRestricted',
    ]),
    ...mapState(useErrorStore, [
      'error',
    ]),
    ...mapState(useAssessmentStore, [
      'pageIsFullSlideTEI',
    ]),
    allowPublicAccess() {
      // The print preview is used by public boards
      return this.isPrintPreview;
    },
    route() {
      return this.$route?.name;
    },
    isCanvas() {
      return ['edit', 'view'].includes(this.route);
    },
    enableHelpButton() {
      return (
        !getLocalData()
        && this.user?.analytics_data
        && this.productSettings
        && get(this.productSettings, `${PRODUCT_SETTING_HAS_SUPPORT_CHAT}.value`)
        && !this.userIsStudent
        && !['print-preview', 'create'].includes(this.route)
      );
    },
    isEmbeddedView() {
      return this.options.embed || this.$route.query.embed;
    },
    isPrintPreview() {
      // Normally we want to read from the $route object so this property can
      // be reactive, but when the app first loads we can get an empty $route
      // object. Fall back to testing the url directly for that case.
      if (this.$route && this.$route.matched.length) {
        return this.$route.name === 'print-preview';
      }

      return window.location.pathname.includes('print-preview');
    },
    isHomeRoute() {
      return ['home', 'published', 'shared-with-me', 'assigned', 'archived', 'activity-templates', 'trash']
        .includes(get(this, '$route.name'));
    },
  },
  mounted() {
    this[types.INIT]({
      allowPublicAccess: this.allowPublicAccess,
      vm: this,
    });

    if (!this.allowPublicAccess) {
      this[types.START_AUTH_CHECK]();
    }

    // Some paste events fire on the body rather than on
    // an actual element deeper in the DOM, and attaching a
    // listener on the body is the only way to catch them
    document.body.addEventListener('paste', this.onBodyPaste);
    bus.initialize();
  },
  created() {
    if (this.$api && this.$apiProgressBar && this.$deApi) {
      const titanApis = [this.$api, this.$apiProgressBar];

      // attach error interceptors to api
      // eventually, we should add more intelligence to the issue
      titanApis.forEach((apiClient) => {
        apiClient.interceptors.response.use((response) => response,
          (error) => {
            if (error.message && error.message.includes('Network Error')) {
              this[types.SET_ERROR]({
                active: true,
                error: {
                  meta: {
                    status_code: this.$t('Network Error'),
                    message: this.$t("Try refreshing the page. If that doesn't work, please contact the support team."),
                  },
                },
              });
            } else if (error.message && error.message.match(/timeout/i)) {
              this[types.SET_ERROR]({
                active: true,
                error: {
                  meta: {
                    status_code: this.$t('Timeout'),
                    message: this.$t("Try refreshing the page. If that doesn't work, please contact the support team."),
                  },
                },
              });
            }

            return error;
          });
      });
    }
  },
  beforeUnmount() {
    document.body.removeEventListener('paste', this.onBodyPaste);
    bus.destroy();
  },
  destroy() {
    this[types.END_AUTH_CHECK]();
  },
  props: {
    /**
     * For injecting options into the app
     */
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};

</script>

<style lang="stylus">
@import './styles/nprogress';
@import './styles/nebula-like-styles';

body {
  color: $comet-text-color-primary-default;
  font-family: $nebula-font-family-body;
}

.page-content {
  padding-top: $studio-navbar-height;

  &--editor {
    padding-top: $studio-navbar-height-util-nav;

    &__extended {
      padding-top: 114px;
    }

    &.page-content--print-preview {
      padding-top: 0;
    }
  }

  &--home {
    padding-top: 0;
  }
}

// Zendesk chat styles, since it is added directly to the body. These have
// inline styles, so we have to use !important
#launcher {
  .studio--view &,
  .studio--print-preview & {
    display: none !important;
  }

  .studio--edit & {
    bottom: 30px !important; // above the editing toolbar
    z-index: 151 !important; // above the editor canvas, but below the chat drawer
  }
}

nebula-like-styles();
</style>
