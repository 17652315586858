<template>
  <ShareModal
    class="modal--studio modal--studio-fixed"
    :asset="asset"
    :config="config"
    :product-setting-codes="productSettingCodes"
    @close-modal="close"
  />
</template>

<script>
/*
  Note: This file is adapted from suite-front-end:
  https://github.com/discoveryedu/suite-front-end/blob/develop/src/components/modals/ShareModal2021.vue
*/
import { ShareModal } from '@discoveryedu/platform-components';
import { mapActions, mapState } from 'pinia';
import * as types from '@/lib/constants/store';
import { PRODUCT_SETTING_CODES } from '@/lib/constants';
import {
  useModalStore,
  useUserStore,
} from '@/stores';

export default {
  name: 'AssetShareModal',
  components: {
    ShareModal,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useUserStore, [
      'userIsStudent',
    ]),
    asset() {
      return {
        assetId: this.options.assetGuid,
        assetUrl: this.options.assetUrl,
        assetType: this.options.type,
        assetTitle: this.options.headline,
        assetDescription: this.options.assetDescription,
      };
    },
    config() {
      return {
        isStudent: this.userIsStudent,
      };
    },
    productSettingCodes() {
      return PRODUCT_SETTING_CODES;
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.options.id });
    },
  },
};
</script>

<style lang="stylus">
.share-modal {
  &__heading,
  &__lms-share-heading {
    margin-top: $nebula-space-half;
  }

  &__lms-container &__lms-share-heading {
    margin-bottom: $nebula-space-1x;
  }
}
</style>
