<template>
  <NebulaDialog
    size="m"
    id="confirm-back-modal"
  >
    <template #header>
      <h1>{{ $t('Exit Editor?') }}</h1>
    </template>
    <template #content>
      <div
        class="confirm-exit-modal__content-container"
      >
        {{ $t('You have unsaved changes.') }}
        {{ $t('If you exit now, you will lose all progress and your changes will not be saved.') }}
        {{ $t('Are you sure you wish to continue?') }}
      </div>
    </template>
    <template #actions-row="{ closeDialog }">
      <NebulaButton
        id="confirm-back-modal-button-continue"
        @click="onContinue"
        type="flat"
        :text="$t('Exit Without Saving')"
        size="l"
      />
      <NebulaButton
        id="confirm-back-modal-button-close"
        @click="closeDialog"
        size="l"
        tabindex="1"
        :text="$t('Resume Editing')"
      />
    </template>
  </NebulaDialog>
</template>

<script>
import {
  NebulaButton,
  NebulaDialog,
} from '@discoveryedu/nebula-components';
import {
  get,
} from 'lodash-es';

export default {
  name: 'ConfirmBackModal',
  components: {
    NebulaDialog,
    NebulaButton,
  },
  methods: {
    async onContinue() {
      const query = get(this, '$route.query', {});

      // if there is a source on the url, then enable
      const { source } = query;
      if (source) {
        document.location.href = source;
      } else {
        window.history.back();
      }
    },
  },
};
</script>

<style lang="stylus">
.confirm-assign {
  &__button-row.comet-modal__button-row {
    display: flex;
    justify-content: space-between;
  }

  &__cancel-button.comet-button {
    margin-left: 0;
  }
}
</style>
