<template>
  <AppMessage
    :heading="heading"
    :duration="5000"
  >
    <template v-if="options.op === 'add'">
      {{ $t(
        '"%(assetName)s" has been added to My Channels.',
        { assetName: decodeHTML(options.assetName), interpolation: { escapeValue: false } })
      }}
    </template>
    <template v-else>
      {{ $t(
        '"%(assetName)s" has been removed from My Channels.',
        { assetName: decodeHTML(options.assetName), interpolation: { escapeValue: false } })
      }}
    </template>
  </AppMessage>
</template>

<script>
import { decodeHTML } from '@/lib/utils';
import AppMessage from './AppMessage.vue';

export default {
  name: 'ChannelSubscribeMessage',
  components: {
    AppMessage,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      decodeHTML,
    };
  },
  computed: {
    heading() {
      return this.options.op === 'add'
        ? this.$t('Added to My Channels')
        : this.$t('Removed from My Channels');
    },
  },
};
</script>
