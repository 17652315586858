<template>
  <BaseModal
    :id="id"
    :confirm-text="$t('Log In')"
    :on-confirm="redirectToLogin"
    :heading="headingText"
    confirm-class="comet-button--primary"
    :cancel-text="$t('Ignore')"
    :on-close="() => {}"
    :on-cancel="cancelModal"
  >
    <p>
      <!-- eslint-disable-next-line max-len -->
      {{ $t("You will need to log in again to continue your progress. Please select 'Log In' below. Any unsaved work will be lost if the login is not completed before submitting any work.") }}
    </p>
    <p>
      {{ $t('You will be redirected back to this tab once you have completed logging in.') }}
    </p>
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import * as types from '@/lib/constants/store';
import BaseModal from '@/components/modals/BaseModal.vue';
import {
  useModalStore,
} from '@/stores';

export default {
  name: 'AuthRedirectModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headingText() {
      return this.options.userFirstName
        ? this.$t('Your session has expired, %(firstName)s', {
          firstName: this.options.userFirstName,
        })
        : this.$t('Your session has expired.');
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    redirectToLogin() {
      window.location.replace(this.options.redirectLink);
    },
    cancelModal() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
  },
};
</script>
