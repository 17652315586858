<template>
  <div
    class="tei-instructions-card"
    :class="{
      [`tei-instructions-card--do-sticker-${doStickerPosition}`]: !!doStickerPosition,
      [`tei-instructions-card--${position}`]: position,
      'tei-instructions-card--view-more': viewMoreVisible,
    }"
  >
    <DoSticker
      v-if="doStickerPosition"
      class="tei-instructions-card__do-sticker"
    />
    <TEIViewMoreContainer
      v-if="content"
      class="tei-instructions-card__view-more-container"
      :max-lines="maxLines"
      :content="content"
      v-slot="props"
      use-js-line-clamp
      @view-more-clicked="onViewMoreClicked"
      @view-more-changed="viewMoreVisible = $event.viewMore"
    >
      <div
        class="tei-instructions-card__content"
        v-html="props.content"
      />
    </TEIViewMoreContainer>
    <!-- If there's no text content, show an empty div to keep the layout from breaking -->
    <div
      v-else
      class="tei-instructions-card__flex-spacer"
    />

    <div
      class="tei-instructions-card__prompt-image-wrapper"
      v-if="imageUrl"
    >
      <img
        class="tei-instructions-card__prompt-image"
        :alt="imageAlt"
        :src="imageUrl"
      >
      <K5ZoomButton
        class="tei-instructions-card__zoom-button"
        @click="onZoomClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import DoSticker from '@/components/ui-elements/texas-icons/DoSticker.vue';
import TEIViewMoreContainer from '@/components/teis/TEIViewMoreContainer.vue';
import K5ZoomButton from '@/components/ui-elements/K5ZoomButton.vue';
import {
  useModalStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  name: 'TEIInstructionsCard',
  components: {
    DoSticker,
    TEIViewMoreContainer,
    K5ZoomButton,
  },
  props: {
    position: {
      type: String,
      default: null, // left, top
    },
    doStickerPosition: {
      type: String,
      default: null, // top-right or bottom-left; null hides the sticker
    },
    content: {
      type: String,
      default: null,
    },
    imageAlt: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    maxLines: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      viewMoreVisible: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    onViewMoreClicked() {
      // View more only shows the prompt in the modal
      this[types.OPEN_MODAL]({
        type: 'TEIInstructionsCardModal',
        content: this.content,
      });
    },
    onZoomClicked() {
      // The zoom button only shows the image in the modal
      this[types.OPEN_MODAL]({
        type: 'TEIInstructionsCardModal',
        imageAlt: this.imageAlt,
        imageUrl: this.imageUrl,
      });
    },
  },
};
</script>

<style lang="stylus">
@import './styles/tei-fonts';

.tei-instructions-card {
  background: var(--tei-instructions-card-color);
  border-radius: 0;
  box-shadow: var(--tei-instructions-card-box-shadow);
  display: flex;
  gap: $nebula-space-2x;
  position: relative;

  &__do-sticker {
    position: absolute;
  }

  &__content {
    flex-grow: 1;
    height: 100%;
    width: 100%;

    /*
      Revert any styles applied to lists and list items to use browser defaults.
      This is needed here since styles on techbook pages override the defaults
      for lists and list items.
    */
    ul, ol {
      list-style-type: revert;
      margin-block-end: revert;
      margin-block-start: revert;
      margin-inline-end: revert;
      margin-inline-start: revert;
      padding-inline-start: revert;

      li {
        list-style-type: revert;
      }
    }

    table {
      border: 2px solid $nebula-color-science-secondary-300;
      border-collapse: separate; // this allows for rounded corners
      border-spacing: 0;
      border-radius: $nebula-border-radius-default;
      overflow: hidden;
      width: 100%;

      th, td {
        tei-mikado-body-small-regular();
        padding: $nebula-space-half;

        &:not(:last-child) {
          border-right: 2px dotted $nebula-color-science-secondary-300;
        }
      }

      // The WYSIWYG styles the first row as heading cells regardless of whether
      // or not they are <th>
      tr:first-child td, th {
        tei-mikado-body-small-bold();

        background-color: $nebula-color-science-secondary-300;
        color: $nebula-color-white;

        &:not(:last-child) {
          border-right: 2px dotted $nebula-color-white;
        }
      }

      tr:not(:last-child):not(:first-child) td {
        border-bottom: 2px solid $nebula-color-science-secondary-300;
      }
    }
  }

  &__view-more-container {
    // Font is getting set here so that the view more container can accurately
    // calculate line height when clamping
    tei-mikado-body-primary-regular();

    flex-grow: 1;
    line-height: $nebula-font-line-height-heading;
  }

  &__prompt-image-wrapper {
    display: block;
    flex-shrink: 0;
    position: relative;
  }

  &__prompt-image {
    border-radius: $nebula-border-radius-default;
    display: block;
    max-height: 90px;
    max-width: 100%;
  }

  &__zoom-button {
    bottom: $nebula-space-2x;
    inset-inline-end: 12px;
    position: absolute;
  }

  &--top {
    align-items: center;
    border-end-end-radius: $nebula-border-radius-large;
    border-end-start-radius: $nebula-border-radius-large;
    max-height: 230px; // 5 lines of text
    min-height: 140px; // 3 lines of text
    justify-content: space-between;
    padding: $nebula-space-3x 60px; // side padding must clear the "DO" sticker

    .tei-instructions-card__content {
      // Allow long prompts to scroll, but short prompts to center vertically
      flex-grow: 1;
      height: auto;
      max-height: 100%;
    }
  }

  &--left {
    align-items: flex-start;
    border-end-end-radius: $nebula-border-radius-large;
    border-start-end-radius: $nebula-border-radius-large;
    flex-direction: column;
    height: 448px;
    padding: $nebula-space-3x;
    width: 248px;

    .tei-instructions-card__prompt-image {
      max-height: 130px;
      max-width: 100%;
    }
  }

  &--do-sticker-top-right {
    .tei-instructions-card__do-sticker {
      inset-inline-end: -40px;
      top: -18px;
    }
  }

  &--do-sticker-bottom-left {
    .tei-instructions-card__do-sticker {
      bottom: -12px;
      inset-inline-start: -32px;
      z-index: 1; // above response cards
    }
  }
}
</style>
