<template>
  <BaseModal
    :id="id"
    :hide-footer="true"
    :heading="$t('You have unsaved answers.')"
    :initial-focus="() => $refs.confirmButton"
  >
    <p>
      {{ $t('Your unsaved answers will be lost if you continue.') }}
      {{ $t('Are you sure you want to continue?') }}
    </p>
    <template #footer>
      <div class="comet-modal__footer modal__footer--margin-3x">
        <div class="comet-modal__button-row">
          <button
            class="nebula-button nebula-button--ghost nebula-button--s comet-modal__button
              comet-modal__button--cancel"
            @click="onClose"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            ref="confirmButton"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
            @click="confirm"
          >
            {{ $t('Yes, Continue') }}
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useAssessmentStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'UnsavedAnswersModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useAssessmentStore, [
      types.UPDATE_UNSUBMITTED_ATTEMPT,
    ]),
    confirm() {
      // Reset the unsubmitted attempt flag, do the original task the user
      // was trying to do, and close this modal
      this[types.UPDATE_UNSUBMITTED_ATTEMPT](false);
      this.options.successCallback();
      this.onClose();
    },
    onClose() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
  },
};
</script>
