<template>
  <component
    v-if="pageLabel === 'Back'"
    :is="useButton ? 'button' : 'RouterLink'"
    class="studio-pagination__item
      nebula-button nebula-button--s nebula-button--ghost"
    :to="!useButton && destination"
    :aria-label="$t('Back')"
  >
    <!-- Back button -->
    <NebulaIcon
      class-override="nebula-button__icon"
      symbol-id="caret-left"
      size="s"
    />
  </component>

  <component
    v-else-if="pageLabel === 'Next'"
    :is="useButton ? 'button' : 'RouterLink'"
    class="studio-pagination__item
      nebula-button nebula-button--s nebula-button--ghost"
    :to="!useButton && destination"
    :aria-label="$t('Next')"
  >
    <!-- Next button -->
    <NebulaIcon
      class-override="nebula-button__icon"
      symbol-id="caret-right"
      size="s"
    />
  </component>

  <div
    v-else-if="pageLabel === 'Ellipsis'"
    class="studio-pagination__item studio-pagination__item--static
      nebula-button nebula-button--s nebula-button--ghost"
  >
    <!-- Ellipsis -->
    <NebulaIcon
      size="s"
      symbol-id="ellipses"
    />
    <span class="nebula-screenreader-only">...</span>
  </div>

  <div
    v-else-if="isCurrentPage"
    aria-current="true"
    class="studio-pagination__item studio-pagination__item--current
      nebula-button nebula-button--s nebula-button--ghost"
  >
    <!-- Current page -->
    <span aria-hidden="true">{{ pageLabel }}</span>
    <span class="nebula-screenreader-only">
      {{ $t('Page %(index)s', { index: pageLabel }) }}
      {{ $t('Current') }}
    </span>
  </div>

  <component
    v-else
    :is="useButton ? 'button' : 'RouterLink'"
    class="studio-pagination__item nebula-button nebula-button--s nebula-button--ghost"
    :to="!useButton && destination"
  >
    <!-- Numbered page -->
    <span aria-hidden="true">{{ pageLabel }}</span>
    <span class="nebula-screenreader-only">
      {{ $t('Page %(index)s', { index: pageLabel }) }}
    </span>
  </component>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
  name: 'StudioPaginationItem',
  components: {
    NebulaIcon,
  },
  props: {
    destination: { // destination for RouterLink
      type: [String, Object],
      default: null,
    },
    isCurrentPage: Boolean, // whether we are currently on this item
    pageLabel: {
      type: [String, Number], // either 'Back', 'Next', 'Ellipsis', or a page number
      required: true,
    },
    useButton: Boolean, // use <button> instead of <a href>
  },
};
</script>

<style lang="stylus">
// Styles are included in the parent component, StudioPagination.vue
</style>
