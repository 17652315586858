<template>
  <AppMessage
    :heading="$t('Removed from Favorites')"
    :duration="5000"
  >
    {{ $t('All items have been removed from Favorites.') }}
  </AppMessage>
</template>

<script>
import AppMessage from './AppMessage.vue';

export default {
  name: 'QuickListDeleteAllMessage',
  components: {
    AppMessage,
  },
};
</script>
