import { defineAsyncComponent } from 'vue';
import AssetShareModal from '@/components/modals/AssetShareModal.vue';
import ConfirmAssignModal from '@/components/modals/ConfirmAssignModal.vue';
import ConfirmRestoreCreation from '@/components/modals/ConfirmRestoreCreation.vue';
import UnsavedAnswersModal from '@/components/modals/UnsavedAnswersModal.vue';
import SearchAssets from '@/components/modals/SearchAssets.vue';
import SharingModal from '@/components/modals/SharingModal.vue';
import ConfirmDeleteBoard from '@/components/modals/ConfirmDeleteBoard.vue';
import CopyCreationModal from '@/components/modals/CopyCreationModal.vue';
import AuthRedirectModal from '@/components/modals/AuthRedirectModal.vue';
import ConfirmNavigateAwayModal from '@/components/modals/ConfirmNavigateAwayModal.vue';
import SubmitAssignmentModal from '@/components/modals/SubmitAssignmentModal.vue';
import FromQuicklist from '@/components/modals/FromQuicklist.vue';
import PublishModal from '@/components/modals/PublishModal.vue';
import InactiveAssetsModal from '@/components/modals/InactiveAssetsModal.vue';
import ImageZoomModal from '@/components/modals/ImageZoomModal.vue';
import PrintLayoutModal from '@/components/modals/PrintLayoutModal.vue';
import RemoveAllQuicklistItemsModal from '@/components/modals/RemoveAllQuicklistItemsModal.vue';
import TEIConnectionCardModal from '@/components/modals/TEIConnectionCardModal.vue';
import TEICheckingAnswersModal from '@/components/modals/TEICheckingAnswersModal.vue';
import TEIInstructionsCardModal from '@/components/modals/TEIInstructionsCardModal.vue';
import TEISelectedResponseZoomModal from '@/components/modals/TEISelectedResponseZoomModal.vue';
import TEIImageZoomModal from '@/components/modals/TEIImageZoomModal.vue';
import TEIStartOverModal from '@/components/modals/TEIStartOverModal.vue';
import ConfirmBackModal from '@/components/modals/ConfirmBackModal.vue';

const ActivityTemplateModal = defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '@/components/modals/ActivityTemplateModal.vue'));

const AddSlideModal = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */'@/components/modals/AddSlideModal.vue'));
const EditorialUploadModal = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */'@/components/modals/EditorialUploadModal.vue'));
const EditorialUploadInfo = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */'@/components/modals/EditorialUploadInfo.vue'));
const ConfirmStillEditingModal = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */'@/components/modals/ConfirmStillEditingModal.vue'));
const TEIErrorModal = defineAsyncComponent(() => import(/* webpackChunkName: "edit-mode" */'@/components/modals/TEIErrorModal.vue'));

export default {
  ActivityTemplateModal,
  AddSlideModal,
  AssetShareModal,
  ConfirmAssignModal,
  EditorialUploadModal,
  EditorialUploadInfo,
  SearchAssets,
  SharingModal,
  ConfirmDeleteBoard,
  ConfirmStillEditingModal,
  CopyCreationModal,
  AuthRedirectModal,
  ConfirmNavigateAwayModal,
  SubmitAssignmentModal,
  FromQuicklist,
  TEIErrorModal,
  PublishModal,
  ConfirmRestoreCreation,
  UnsavedAnswersModal,
  InactiveAssetsModal,
  ImageZoomModal,
  PrintLayoutModal,
  RemoveAllQuicklistItemsModal,
  TEIConnectionCardModal,
  TEICheckingAnswersModal,
  TEIInstructionsCardModal,
  TEISelectedResponseZoomModal,
  TEIImageZoomModal,
  TEIStartOverModal,
  ConfirmBackModal,
};
