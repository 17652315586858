<template>
  <Teleport
    v-if="appMessage"
    to="body"
    :disabled="!usePortal"
  >
    <div>
      <component
        :is="appMessage.type"
        :options="appMessage"
      />
    </div>
  </Teleport>
</template>

<script>
import { mapState } from 'pinia';
import messages from '@/components/messages';
import {
  useMessageStore,
} from '@/stores';

export default {
  components: {
    ...messages,
  },
  props: {
    usePortal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useMessageStore, [
      'appMessage',
    ]),
  },
};
</script>
