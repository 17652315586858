<template>
  <BaseModal
    class="tei-selected-response-zoom-modal"
    :id="id"
    :is-wide="false"
    header-margin="2x"
    heading=" "
    hide-footer
  >
    <img
      v-if="showImage"
      class="tei-selected-response-zoom-modal__image"
      :src="option.resource.url"
    >
    <template v-if="showText">
      <span class="tei-selected-response-zoom-modal__marker">
        {{ option.marker }}.
      </span>
      <div
        v-if="text"
        class="tei-selected-response-zoom-modal__text"
        v-html="text"
      />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';
import { extractHtml } from '@/lib/utils';

export default {
  name: 'TEISelectedResponseZoomModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    contentType() {
      // This is either 'text' (show only text), 'image' (show only the image),
      // or 'text-and-image' (show both)
      return this.options.contentType || 'text';
    },
    option() {
      return this.options.option || {};
    },
    showImage() {
      return !!(['text-and-image', 'image'].includes(this.contentType) && this.option.resource?.url);
    },
    showText() {
      return ['text-and-image', 'text'].includes(this.contentType);
    },
    text() {
      return extractHtml(this.option.text || this.option.resource.mediaCaption);
    },
  },
};
</script>

<style lang="stylus">
@import '../teis/styles/tei-fonts';

.tei-selected-response-zoom-modal {
  .comet-modal__inner {
    width: 558px;
  }

  &__image {
    aspect-ratio: 16 / 9;
    display: block;
    margin-bottom: $nebula-space-1x;
    object-fit: contain;
    width: 100%;
  }

  &__marker {
    tei-mikado-heading-secondary-bold();
  }

  &__text {
    tei-mikado-body-small-regular();
  }
}
</style>
