<template>
  <div class="permissions-dropdown">
    <button
      ref="dropdownButton"
      class="comet-button
        comet-button--flat
        comet-button--dark
        comet-dropdown-trigger
        comet-button--icon-with-text"
      :class="{
        'comet-dropdown-trigger--menu-visible': dropdownOpen,
        'comet-button--active comet-button--hover': dropdownOpen,
      }"
      :aria-describedby="`permissions-dropdown__aria-description--${uniqueId}`"
      :aria-expanded="dropdownOpen.toString()"
      aria-haspopup="true"
      @click="toggleDropdown"
    >
      <span class="comet-button__inner">
        <span class="comet-button__text">
          {{ selectedItem.label }}
        </span>
        <NebulaIcon
          symbol-id="caret-down"
          class="comet-button__icon comet-button__icon--right"
          size="m"
        />
      </span>
      <span
        :id="`permissions-dropdown__aria-description--${uniqueId}`"
        class="permissions-dropdown__aria-description"
      >
        {{ $t('Change sharing permissions') }}
      </span>
    </button>
    <FocusTrap
      :active="dropdownOpen"
      :initial-focus="() => $refs.dropdownList"
      :fallback-focus="() => $refs.dropdownList"
      @deactivate="dropdownOpen = false"
    >
      <div
        ref="dropdown"
        class="comet-dropdown-menu comet-popover permissions-dropdown__dd"
        :class="{ visible: dropdownOpen }"
        v-show="dropdownOpen"
        :aria-hidden="!dropdownOpen"
        :style="dropdownPosition"
      >
        <div
          class="comet-list-group comet-dropdown-menu__list-group comet-list-group--no-hairlines"
        >
          <ul
            ref="dropdownList"
            class="comet-list-group__list"
            role="menu"
            :aria-label="$t('Sharing permissions')"
            tabindex="-1"
            @keyup="onKeyUp"
          >
            <li
              v-for="item in permissionsOptions"
              :key="item.id"
              class="comet-list-group__row"
              :class="{
                'comet-list-group__row--selected': item.id === selectedId,
              }"
            >
              <button
                ref="permissionOptionButtons"
                class="comet-list-group__row-anchor"
                @click="select(item.id)"
                role="menuitem"
              >
                <span class="comet-list-group__row-label">
                  {{ item.label }}
                  <br>
                  <span class="permissions-dropdown__sublabel">
                    {{ item.description }}
                  </span>
                </span>
                <span
                  class="comet-screenreader-only"
                  v-if="item.id === selectedId"
                >
                  {{ $t('(selected)') }}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </FocusTrap>
  </div>
</template>

<script>
import { first, last, uniqueId } from 'lodash-es';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { FocusTrap } from 'focus-trap-vue';
import { mapState } from 'pinia';
import { sharingTypes } from '@/lib/constants';
import {
  useUserStore,
} from '@/stores';

export default {
  name: 'PermissionsDropdown',
  components: {
    NebulaIcon,
    FocusTrap,
  },
  props: {
    selectedId: {
      type: String,
      default: 'view',
    },
  },
  emits: ['select-share-permissions'],
  data() {
    return {
      dropdownOpen: false,
      dropdownPosition: null,
      container: null,
      uniqueId: uniqueId(),
    };
  },
  computed: {
    /*
      note: still in studio module and not in studio/share. will need to be decoupled
      if we ever truly support share w/o studio
    */
    ...mapState(useUserStore, [
      'userSharingTypeIds',
    ]),
    selectedItem() {
      return sharingTypes()[this.selectedId];
    },
    permissionsOptions() {
      return this.userSharingTypeIds.map((id) => ({
        id,
        ...sharingTypes()[id],
      }));
    },
  },
  methods: {
    onKeyUp(event) {
      const forwardKeys = ['ArrowDown', 'ArrowRight'];
      const backwardKeys = ['ArrowUp', 'ArrowLeft'];
      if (!forwardKeys.concat(backwardKeys).includes(event.key)) return;
      const focusedEl = document.activeElement;
      if (this.$refs?.permissionOptionButtons?.length) {
        const buttons = this.$refs.permissionOptionButtons;
        const currentIndex = buttons.findIndex((button) => button === focusedEl);
        if (currentIndex === -1) {
          if (forwardKeys.includes(event.key)) first(buttons).focus();
          if (backwardKeys.includes(event.key)) last(buttons).focus();
        } else if (forwardKeys.includes(event.key)) {
          const nextIndex = currentIndex + 1;
          buttons[nextIndex < buttons.length ? nextIndex : 0].focus();
        } else if (backwardKeys.includes(event.key)) {
          const previousIndex = currentIndex - 1;
          buttons[previousIndex < 0 ? buttons.length - 1 : previousIndex].focus();
        }
      }
      event.stopPropagation();
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    onBodyClick({ target }) {
      // Close the dropdown if we click outside of it
      if (!this.$el.contains(target)) {
        this.closeDropdown();
      }
    },
    select(id) {
      this.$emit('select-share-permissions', id);
      this.closeDropdown();
    },
    toggleDropdown() {
      if (!this.dropdownOpen) {
        // Calculate position of the dropdown and display it. This has to be manually
        // positioned in js so that it can extend outside its scroll container
        const { right: buttonRight, bottom: buttonBottom } = this.$refs.dropdownButton
          .getBoundingClientRect();
        const { left: leftOffset, top: topOffset } = this.$container.getBoundingClientRect();

        this.dropdownPosition = {
          left: `${buttonRight - leftOffset}px`,
          top: `${buttonBottom - topOffset}px`,
          transform: '',
        };
        this.dropdownOpen = true;

        this.$nextTick(() => {
          // After the dropdown has rendered, prevent it from clipping out of the viewport
          const dropdown = this.$refs.dropdownList.closest('.permissions-dropdown__dd');
          if (dropdown) {
            const { bottom } = dropdown.getBoundingClientRect();
            const bottomOffset = Math.min(window.innerHeight - 16 - bottom, 0);
            this.dropdownPosition.transform = `translate(-100%, ${bottomOffset}px)`;
          }
        });
      } else {
        // Close the dropdown
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$container = this.$el.closest('.share-ui');
      document.addEventListener('click', this.onBodyClick);
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onBodyClick);
  },
};
</script>

<style lang="stylus">
.permissions-dropdown {
  font-size: $nebula-font-size-body-2;
  position: relative;
  white-space: nowrap;

  &__aria-description {
    display: none;
  }

  .comet-button {
    border-radius: 3px; // snap inside a container with the standard 4px radius
    font-size: $nebula-font-size-body-2;
    margin: 0;
  }

  .comet-button__icon {
    height: $nebula-space-2x;
    width: $nebula-space-2x;
  }

  .comet-list-group__row-anchor {
    background: none;
    border: none;
    text-align: start;
  }

  .permissions-dropdown__sublabel {
    color: $comet-color-neutral-50;
    font-size: $nebula-font-size-caption;
  }

  &__dd.comet-dropdown-menu { // .permissions-dropdown__dd.comet-dropdown-menu
    position: fixed;
    transform: translateX(-100%);
  }
}
</style>
