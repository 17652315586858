<template>
  <BaseModal
    ref="baseModal"
    :id="id"
    class="modal--sharing"
    :on-close="onClose"
    :on-cancel="onClose"
    :on-confirm="onConfirm"
    :confirm-text="confirmText"
    :cancel-text="cancelText"
    :heading="modalHeading"
    :disable-confirm-button="disableButtons"
    :disable-cancel-button="disableButtons"
    cancel-class="sharing-modal-buttons__button-left"
    :initial-focus="() => $refs?.ShareUI?.$refs?.inviteForm?.$refs?.inviteeInput?.$el"
    is-fixed
  >
    <ShareUI
      :data="draft"
      ref="ShareUI"
      @error="onError"
      @submitted="onSubmitted"
      @submit-attempt="onSubmit"
      :signin-url="signinUrl"
    />
  </BaseModal>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { get } from 'lodash-es';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import ShareUI from '@/components/share/ShareUI.vue';
import { sharingTypes } from '@/lib/constants';
import {
  useEditorStore,
  useModalStore,
  useToastStore,
  useUserStore,
} from '@/stores';

export default {
  name: 'SharingModal',
  components: {
    BaseModal,
    ShareUI,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      mode: String, // out of ['PUBLISH', 'UPDATE']
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'draftType',
      'isLocked',
      'draftIsDirty',
    ]),
    ...mapState(useUserStore, [
      'user',
    ]),
    modalHeading() {
      if (this.draftType === 'lesson') {
        return this.$t('Share your lesson with others');
      }

      return this.draftType === 'board'
        ? this.$t('Share your board with others')
        : this.$t('Share your slideshow with others');
    },
    draftHasBeenPublished() {
      return get(this, '$route.name') === 'view'
        || get(this.draft, 'metadata.published_on');
    },
    signinUrl() {
      return get(this, 'user.signin_url', false);
    },
  },
  data() {
    return {
      disableConfirmButton: false,
      error: false,
      publicAccessType: null,
      shares: {},
      sharingTypes,
      cancelText: this.$t('Cancel'),
      confirmText: this.$t('Share'),
      allowForceCancel: false,
      disableButtons: false,
      published: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
      types.OPEN_MODAL,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    ...mapActions(useEditorStore, [
      types.UPDATE_CURRENTLY_EDITING,
      types.SET_EDITOR_STATUS_MESSAGE,
      types.SET_DRAFT_IS_DIRTY,
      types.PUBLISH_DRAFT,
      types.UPDATE_SHARES,
      types.UPDATE_SHARE_ID,
    ]),
    continueEditing() {
      const focusTrap = get(this.$refs, 'baseModal.$refs.focusTrap');
      if (focusTrap) focusTrap.activate();
    },
    onClose() {
      const shareUI = this.$refs?.ShareUI;
      if (!shareUI) return true;
      const canCancel = shareUI.canCancel();

      if (canCancel || this.allowForceCancel) {
        this[types.CLOSE_MODAL]({ id: this.id });

        if (this.$route.name === 'edit') {
          this[types.UPDATE_CURRENTLY_EDITING]({ sharingModal: null });
        }
      } else {
        this.cancelText = this.$t('Exit Without Saving');
        this.confirmText = this.$t('Save and Exit');
        this.allowForceCancel = true;
      }
      return true;
    },
    onConfirm() {
      this.$refs.ShareUI.submit();
    },
    onError(e) {
      this[types.SET_EDITOR_STATUS_MESSAGE]({
        message: e.message,
      });
    },
    onSubmit() {
      this.disableButtons = true;
    },
    async onSubmitted({
      success,
      source,
      shares,
      shareId,
    }) {
      this.disableButtons = false;

      if (success) {
        // register with the yDoc
        this[types.UPDATE_SHARES](shares);

        // no current share id?
        if (!this.draft.share_id && shareId) {
          this[types.UPDATE_SHARE_ID](shareId);
        }
      }

      // close modal if not copy link
      if (source !== 'copyLink') {
        this[types.CLOSE_MODAL]({ id: this.id });
      }

      // publish if we're in publish mode
      if (success && this.options && this.options.mode === 'PUBLISH') {
        // publish if we haven't already or if the draft has been updated
        if (this.draftIsDirty || !this.published) {
          this[types.SET_DRAFT_IS_DIRTY](false);
          await this[types.PUBLISH_DRAFT]({ draftId: this.draft.id });
          this.published = true;
        }

        if (source !== 'copyLink') {
          const creationPointerAssetReference = this.draft.references
            .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

          // Go to view mode
          this.$router.push({
            path: 'view',
            query: {
              id: creationPointerAssetReference && creationPointerAssetReference.reference_id,
            },
          });
        }
      }

      // everything other than copy link shows the message
      if (source !== 'copyLink') {
        // set the message
        this[types.SET_EDITOR_STATUS_MESSAGE]({
          message: 'share-confirmation',
          duration: this.options.mode === 'PUBLISH' ? 10000 : 5000,
        });
      }
    },
  },
};
</script>

<style lang="stylus">
.modal--sharing .comet-modal .modal__inner {
  display: flex;
  flex-direction: column;
}

.user-shares-list {
  flex-shrink: 1;
  list-style-type: none;
  margin: 0 0 $nebula-space-4x 0;
  overflow: auto;
  padding: 0;

  &__share { // .user-shares-list__share
    align-items: center;
    border-bottom: $comet-border-hairline-default;
    display: flex;
    padding-inline-start: $nebula-space-2x;

    &--owner { // user-shares-list__share--owner
      border-color: $comet-color-neutral-60;
    }

    &--static { // .user-shares-list__share--static
      padding-inline-end: $nebula-space-2x;
    }
  }

  &__name { // .user-shares-list__name
    flex-grow: 1;
    padding-block: $nebula-space-2x;
    padding-inline: $nebula-space-1x $nebula-space-2x;
  }

  &__remove-button.comet-button { // .user-shares-list__remove-button.comet-button
    align-items: center;
    display: flex;
    height: 42px; // match default comet button
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  &__remove-icon.comet-button__icon { // .user-shares-list__remove-icon.comet-button__icon
    height: $nebula-space-2x;
    width: $nebula-space-2x;
  }
}

.share-avatar {
  background-color: $comet-color-lime-30; // after ORION-3636 this will be the student color
  border-radius: 50%;
  color: $nebula-color-white;
  flex-shrink: 0;
  height: $nebula-space-4x;
  line-height: $nebula-space-4x;
  text-align: center;
  width: $nebula-space-4x;

  &--owner {
    background-color: $comet-color-midnight-50;
  }

  &--user {
    background-color: $comet-color-plum-50;
  }

  &--classroom, &--group {
    background-color: $comet-color-orchid-50;
  }
}

.sharing-modal-buttons {
  // .sharing-modal-buttons__button-row.comet-modal__button-row
  &__button-row.comet-modal__button-row {
    justify-content: space-between;
  }

  &__cancel.comet-button { // .sharing-modal-buttons__cancel.comet-button
    margin-inline-start: 0;
  }

  &__last-published-message { // .sharing-modal-buttons__last-published-message
    flex-grow: 1;
    padding-inline-end: $nebula-space-2x;
    text-align: end;
  }

  &__button-left {
    margin: 0;
    margin-inline-end: auto;
  }
}

.user-shares-list {
  max-height: 330px;
}
</style>
