<template>
  <BaseModal
    :id="id"
    :heading="heading"
    class="modal--submit-assignment submit-assignment"
    :initial-focus="() => $refs.submitButton"
  >
    <CometBlockMessage
      v-if="error"
      block-message-type="error"
    >
      <p>{{ error }}</p>
    </CometBlockMessage>
    <p v-if="attemptHasUnansweredQuestions">
      {{ $t('You have outstanding questions that still need to be answered.') }}
      {{ $t('You may only submit the assignment once you have answered all questions.') }}
    </p>
    <p v-else>
      {{ $t('Once you submit the assignment you cannot make any changes.') }}
      {{ $t('Are you sure you want to submit the assignment?') }}
    </p>
    <template #footer>
      <div class="comet-modal__footer modal__footer--margin-3x">
        <div
          v-if="attemptHasUnansweredQuestions"
          class="comet-modal__button-row"
        >
          <button
            ref="submitButton"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
            @click="cancel"
          >
            {{ $t('Return to Assignment') }}
          </button>
        </div>
        <div
          v-else
          class="comet-modal__button-row"
        >
          <button
            class="nebula-button nebula-button--ghost nebula-button--s comet-modal__button
              comet-modal__button--cancel submit-assignment__cancel"
            @click="cancel"
          >
            {{ $t('No, Cancel') }}
          </button>
          <button
            ref="submitButton"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
            :class="loading && 'nebula-button--disabled'"
            :disabled="loading"
            @click="submit"
          >
            {{ $t('Yes, Submit Assignment') }}
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import fscreen from 'fscreen';
import BaseModal from '@/components/modals/BaseModal.vue';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import * as types from '@/lib/constants/store';
import {
  useAssessmentStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'SubmitAssignmentModal',
  components: {
    BaseModal,
    CometBlockMessage,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useAssessmentStore, [
      'domains',
      'attempt',
      'attemptHasUnansweredQuestions',
    ]),
    heading() {
      return this.attemptHasUnansweredQuestions
        ? this.$t('Assignment Not Complete')
        : this.$t('Submit Your Assignment?');
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useAssessmentStore, [
      types.SUBMIT_ATTEMPT,
    ]),
    cancel() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    exitFullscreen() {
      if (fscreen.fullscreenElement !== null) {
        fscreen.exitFullscreen();
      }
    },
    async submit() {
      try {
        this.error = null;
        this.loading = true;
        await this[types.SUBMIT_ATTEMPT]();
        this.exitFullscreen();

        // After a successful submission, redirect to Student Results
        window.location.href = `${this.domains.appDomain}/assessment/results/${this.attempt.id}`;
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="stylus">
.submit-assignment {
  &__cancel {
    margin: 0 auto 0 0;
  }
}
</style>
