<template>
  <BaseModal
    :id="id"
    class="confirm-clear-answer-modal"
    :heading="$t('Start Over')"
    :initial-focus="() => $refs.startOverButton"
    :close-on-backdrop-click="!busy"
    is-fixed
  >
    <div class="comet-long-form-text">
      <p>
        {{ $t('This will clear your tries and restart the question.') }}
        {{ $t('Are you sure that you want to start over?') }}
      </p>
    </div>
    <template #footer>
      <div class="comet-modal__footer">
        <div class="comet-modal__button-row confirm-assign__button-row">
          <button
            :disabled="busy"
            class="nebula-button nebula-button--ghost nebula-button--s comet-modal__button
              comet-modal__button--cancel confirm-assign__cancel-button"
            :class="{ 'nebula-button--disabled': busy }"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            ref="startOverButton"
            :disabled="busy"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
            :class="{ 'nebula-button--disabled': busy }"
            @click="onContinue"
          >
            {{ $t('Start Over') }}
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useAssessmentStore,
  useModalStore,
  useToastStore,
} from '@/stores';

export default {
  name: 'TEIStartOverModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
      types.OPEN_MODAL,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    ...mapActions(useAssessmentStore, [
      types.CLEAR_ATTEMPT,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    async onContinue() {
      this.busy = true;
      await this[types.CLEAR_ATTEMPT]();
      this.close();
    },
  },
};
</script>

<style lang="stylus">
.confirm-assign {
  &__button-row.comet-modal__button-row {
    display: flex;
    justify-content: space-between;
  }

  &__cancel-button.comet-button {
    margin-left: 0;
  }
}
</style>
