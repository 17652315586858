<template>
  <transition
    name="studio-toast-transition"
    enter-from-class="studio-toast-transition--enter-from"
    enter-active-class="studio-toast-transition--enter-active"
    leave-active-class="studio-toast-transition--leave-active"
    leave-to-class="studio-toast-transition--leave-to"
  >
    <div
      role="alert"
      class="studio-toast-message"
      :class="{ 'studio-toast-message--top': studioToast.position === 'top' }"
      v-if="studioToast.type"
    >
      <CometBlockMessage :block-message-type="studioToast.type">
        <p>{{ studioToast.message }}</p>
      </CometBlockMessage>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'pinia';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import {
  useToastStore,
} from '@/stores';

export default {
  name: 'StudioToast',
  components: {
    CometBlockMessage,
  },
  computed: {
    ...mapState(useToastStore, [
      'studioToast',
    ]),
  },
};
</script>

<style lang="stylus">
.studio-toast-message {
  left: 50%;
  padding-top: $nebula-space-2x;
  position: absolute;
  top: 80%;
  transform: translateX(-50%);
  z-index: 1200;

  &--top {
    padding-top: 0;
    top: 96px;
  }
}

.studio-toast-transition--enter-active {
  transition: $nebula-transition-default;
}
.studio-toast-transition--leave-active {
  transition: $nebula-transition-default;
}
.studio-toast-transition--enter-from,
.studio-toast-transition--leave-to {
  opacity: 0;
  transform: translate(-50%,100%);
}
</style>
