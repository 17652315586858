import DeletedCreationMessage from './DeletedCreationMessage.vue';
import MovedToTrashMessage from './MovedToTrashMessage.vue';
import QuickListErrorMessage from './QuickListErrorMessage.vue';
import QuickListMessage from './QuickListMessage.vue';
import QuickListDeleteAllMessage from './QuickListDeleteAllMessage.vue';
import ChannelSubscribeErrorMessage from './ChannelSubscribeErrorMessage.vue';
import ChannelSubscribeMessage from './ChannelSubscribeMessage.vue';

export default {
  DeletedCreationMessage,
  MovedToTrashMessage,
  QuickListErrorMessage,
  QuickListMessage,
  QuickListDeleteAllMessage,
  ChannelSubscribeErrorMessage,
  ChannelSubscribeMessage,
};
