<template>
  <div
    id="zoom-help-button"
    class="help-button"
    v-if="showZoomAgent"
    :style="style"
  >
    <NebulaIcon
      class="help-button__icon"
      symbol-id="chat-active"
    />
    <span>Chat</span>
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { mapState } from 'pinia';
import { useUserStore } from '@/stores';
import { loadZendesk, loadZoomAgent } from '@/lib/pendo';
import i18next from '@/lib/i18next';

export default {
  components: {
    NebulaIcon,
  },
  props: {
    verticalSpacingPx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showZendesk: false,
      showZoomAgent: false,
    };
  },
  emits: ['input'],
  computed: {
    ...mapState(useUserStore, [
      'user',
    ]),
    style() {
      return {
        bottom: `${this.verticalSpacingPx}px`,
      };
    },
  },
  created() {
    const userStore = useUserStore();
    if (
      this.user?.analytics_data
    ) {
      const countryCode = userStore.user.analytics_data.site.country_code.toUpperCase();
      // Zendesk is shown to users whose site is in GB and who have the support chat
      // product setting. It is hidden for students and for the thumbnail loader
      this.showZendesk = ['GB'].includes(countryCode);

      // Zoom is shown for users in US/CA who don't have arabic set as their language
      this.showZoomAgent = ['US', 'CA'].includes(countryCode)
        && i18next.language !== 'ar';

      // Initialize Pendo, Zendesk and GTM
      if (this.showZoomAgent) {
        loadZoomAgent();
      } else if (this.showZendesk) {
        loadZendesk(countryCode);
      }
    }
  },
};
</script>

<style lang="stylus">
  .help-button {
      align-items: center;
      background-color: $nebula-color-platform-interactive-800;
      border-radius: 999rem;
      bottom: 0.625rem;
      color: $nebula-color-white;
      cursor: pointer;
      display: flex;
      font-size: 1rem;
      font-weight: 700;
      padding: 0.8rem 1.5rem;
      position: fixed;
      right: 20px;
      z-index: 100;
  }
  .help-button__icon {
      fill: $nebula-color-white;
      margin-inline-end: var(--nebula-space-1x);
  }
  .help-button--rtl {
      left: 20px;
      right: auto;
  }
</style>
