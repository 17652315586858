import { mapActions, mapState } from 'pinia';
import { get, first } from 'lodash-es';
import * as types from '@/lib/constants/store';
import { bus } from '@/lib/eventBus';
import { bottomYPosition, scanGrid, createTopZPosition } from '@/lib/utils/grid';
import { assetModuleDefault } from '@/lib/constants/moduleDefaults';
import {
  EDITOR_CANVAS_WIDTH,
  MODAL_CANVAS_WIDTH,
  MODAL_CANVAS_HEIGHT,
  MODAL_CANVAS_PLACE_MODULE_BLEED,
} from '@/lib/constants';
import placeModule from '@/lib/utils/placeModule';
import canvasUtils from '@/mixins/canvasUtils';
import {
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  mixins: [canvasUtils],
  data() {
    return {
      assetSelection: [],
    };
  },
  props: {
    options: Object,
  },
  computed: {
    ...mapState(useEditorStore, [
      'draftType',
      'currentPage',
      'gridIsDisabled',
      'modulesOrderedByZPosition',
      'getModulesByModalIdOrderedByZPosition',
      'currentModalCanvasId',
      'gridMatrix',
    ]),
    gridIsOff() {
      return this.options.canvasName !== 'notes' && this.gridIsDisabled;
    },
    modulesOrderedByZ() {
      if (this.options.canvasName === 'modalModules') {
        return this.getModulesByModalIdOrderedByZPosition(this.currentModalCanvasId);
      }

      return this.modulesOrderedByZPosition;
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.ADD_MODULE,
      types.REPLACE_MODULE,
    ]),
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    isSelected(assetId) {
      return !!this.assetSelection.find((selectedAsset) => selectedAsset.id === assetId);
    },
    onConfirm() {
      // If the parent component wants us to do something else on confirm rather than add modules,
      // just do that and close
      if (this.options.onConfirm) {
        this.options.onConfirm(this.assetSelection);
        this[types.CLOSE_MODAL]({ id: this.id });
        return;
      }

      if (this.options.moduleToReplace) {
        // Only ever going to be one index if it's replacing a module
        const asset = first(this.assetSelection);
        const newModule = assetModuleDefault(this.gridIsOff, asset);

        newModule.options = {
          ...newModule.options,
          ...this.options.moduleToReplace.options,
          asset_id: asset.id,
        };

        newModule.layout = this.options.moduleToReplace.layout;

        this[types.REPLACE_MODULE]({
          canvasName: this.options.canvasName,
          payload: newModule,
          replacedModuleId: this.options.moduleToReplace.id,
        }).then((newModuleId) => {
          bus.emit('activeModule', {
            canvasName: this.options.canvasName,
            id: newModuleId,
          });
        });
      } else if (this.options.canvasName !== 'notes') {
        const assetModulesWithInvalidPositions = [];
        // Scan the grid if we're in the editor canvas
        this.assetSelection.forEach((asset) => {
          try {
            const newModule = assetModuleDefault(this.gridIsOff, asset);
            newModule.options.asset_id = asset.id;

            let destinationCoords;

            if (this.gridIsOff) {
              let canvasInfo;
              if (this.options.canvasName === 'modalModules') {
                canvasInfo = {
                  width: MODAL_CANVAS_WIDTH,
                  height: MODAL_CANVAS_HEIGHT,
                  bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
                };
              } else {
                canvasInfo = {
                  width: EDITOR_CANVAS_WIDTH,
                  height: this.currentPage.options.height,
                };
              }

              canvasInfo.defaultPosition = this.getVisibleCanvasCenter(newModule);

              destinationCoords = placeModule(
                this.modulesOrderedByZ,
                newModule,
                canvasInfo,
              );
            } else {
              destinationCoords = this.scanGrid(this.gridMatrix, newModule, this.draftType === 'board');
            }

            Object.assign(newModule.layout, {
              x_position: destinationCoords.x,
              y_position: destinationCoords.y,
              height: destinationCoords.height || newModule.layout.height,
            });

            if (this.gridIsOff) {
              newModule.layout.z_position = createTopZPosition(this.modulesOrderedByZ);
            }

            // Add module to the canvas
            this[types.ADD_MODULE]({
              canvasName: this.options.canvasName,
              payload: newModule,
            }).then((newModuleId) => {
              bus.emit('activeModule', {
                canvasName: this.options.canvasName,
                id: newModuleId,
              });
            });
          } catch (e) {
            // @todo push this exception to nr so that we don't fail silently
            assetModulesWithInvalidPositions.push(asset);
          }
        });

        if (assetModulesWithInvalidPositions.length) {
          bus.emit('invalidPlacementAttempted', assetModulesWithInvalidPositions);
        }
      } else {
        const vm = this; // Preserve context for arrow function
        vm.assetSelection.forEach((asset) => {
          const newModule = assetModuleDefault(this.gridIsOff, asset);
          // Add module to the bottom of the notes canvas
          newModule.options.asset_id = asset.id;
          newModule.layout.y_position = bottomYPosition(get(vm, 'currentPage.notes', []));
          vm[types.ADD_MODULE]({
            canvasName: vm.options.canvasName,
            payload: newModule,
          }).then((newModuleId) => {
            bus.emit('activeModule', {
              canvasName: vm.options.canvasName,
              id: newModuleId,
            });
          });
        });
      }

      // Execute callbacks
      if (this.options.afterConfirm) {
        this.options.afterConfirm();
      }

      // And close the modal
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    onSelectAsset({ target }, asset) {
      // If there is a module to replace, then we only want to limit selections to one
      if (this.options.moduleToReplace || this.options.singleSelect) {
        this.assetSelection = this.assetSelection.some((obj) => obj.id === target.value)
          ? []
          : [{
            id: target.value,
            title: target.title,
            type: asset.type,
            group: asset.group,
          }];
      } else if (target.checked) { // We are not replacing a module, so we can add multiple assets
        this.assetSelection.push({
          id: target.value,
          title: target.title,
          type: asset.type,
          group: asset.group,
        });
      } else {
        this.assetSelection.splice(this.assetSelection
          .findIndex((obj) => obj.id === target.value), 1);
      }
    },
    scanGrid,
  },
};
