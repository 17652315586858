import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import {
  USER_UPLOADABLE_ASSET_TYPES,
  EDITOR_CANVAS_WIDTH,
  MODAL_CANVAS_WIDTH,
  MODAL_CANVAS_HEIGHT,
  MODAL_CANVAS_PLACE_MODULE_BLEED,
} from '@/lib/constants';
import {
  assetModuleDefault,
  uploadModuleDefault,
} from '@/lib/constants/moduleDefaults';
import { bus } from '@/lib/eventBus';
import {
  bottomYPosition,
  createTopZPosition,
  scanGrid,
} from '@/lib/utils/grid';
import placeModuleWithoutGrid from '@/lib/utils/placeModule';
import canvasUtils from '@/mixins/canvasUtils';
import {
  useEditorStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  mixins: [canvasUtils],
  computed: {
    ...mapState(useEditorStore, [
      'currentPage',
      'draftType',
      'getModulesByModalIdOrderedByZPosition',
      'gridIsDisabled',
      'modulesOrderedByZPosition',
      'activeCanvasName',
      'currentModalCanvasId',
      'gridMatrix',
    ]),
    destinationCanvasName() {
      // The AddModule component has a specified canvas name
      if (this.canvasName) return this.canvasName;

      // Otherwise read from Pinia to decide the current canvas
      return this.currentModalCanvasId
        ? 'modalModules'
        : (this.activeCanvasName || 'modules');
    },
    gridIsOff() {
      return this.destinationCanvasName !== 'notes' && this.gridIsDisabled;
    },
    modulesOrderedByZ() {
      if (this.destinationCanvasName === 'modalModules') {
        return this.getModulesByModalIdOrderedByZPosition(this.currentModalCanvasId);
      }

      return this.modulesOrderedByZPosition;
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.ADD_MODULE,
      types.REPLACE_MODULE,
    ]),
    addModulesFromMyContent(assets) {
      if (!assets.length) return;

      if (this.moduleToReplace) {
        // If we're replacing a module, the array will only contain a single asset
        const asset = assets[0];

        // Users can create other assets like TEAs or Boards, but those are not really "uploads"
        const isUpload = asset.is_user_created
          && USER_UPLOADABLE_ASSET_TYPES.includes(asset.type_id || asset.type.id);
        const mod = isUpload
          ? uploadModuleDefault(this.gridIsOff)
          : assetModuleDefault(this.gridIsOff, asset);
        mod.options.asset_id = asset.id;
        this.placeModule(mod);
        return;
      }

      // If we have more than one asset/upload, try adding them until we have trouble
      const modulesWithInvalidPositions = [];

      assets.forEach((asset) => {
        // Users can create other assets like TEAs or Boards, but those are not really "uploads"
        const isUpload = asset.is_user_created
          && USER_UPLOADABLE_ASSET_TYPES.includes(asset.type_id || asset.type.id);
        const mod = isUpload
          ? uploadModuleDefault(this.gridIsOff)
          : assetModuleDefault(this.gridIsOff, asset);
        mod.options.asset_id = asset.id;

        if (this.destinationCanvasName !== 'notes') {
          // Scan the grid if we're in the editor or modal canvas
          try {
            let coordinates;
            let canvasInfo;

            if (this.gridIsOff) {
              if (this.destinationCanvasName === 'modalModules') {
                canvasInfo = {
                  width: MODAL_CANVAS_WIDTH,
                  height: MODAL_CANVAS_HEIGHT,
                  bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
                };
              } else {
                canvasInfo = {
                  width: EDITOR_CANVAS_WIDTH,
                  height: this.currentPage.options.height,
                };
              }

              canvasInfo.defaultPosition = this.getVisibleCanvasCenter(mod);

              coordinates = placeModuleWithoutGrid(
                this.modulesOrderedByZ,
                mod,
                canvasInfo,
              );
            } else {
              coordinates = this.scanGrid(this.gridMatrix, mod, this.draftType === 'board');
            }

            const layoutOverrides = {
              x_position: coordinates.x,
              y_position: coordinates.y,
            };

            if (this.gridIsOff) {
              // Assign the next z coordinate above other modules.
              layoutOverrides.z_position = createTopZPosition(this.modulesOrderedByZ);
            }

            this.addModuleToState(mod, layoutOverrides);
          } catch {
            modulesWithInvalidPositions.push(asset);
          }
        } else {
          this.placeModule(mod);
        }
      });

      // If any did not fit on the canvas, show their titles in a toast message
      if (modulesWithInvalidPositions.length) {
        bus.emit('invalidPlacementAttempted', modulesWithInvalidPositions);
      }
    },
    async addModuleToState(mod, layoutOverrides) {
      let newModuleId;
      const moduleToAdd = {
        ...mod,
        layout: {
          ...mod.layout,
          ...layoutOverrides,
        },
      };

      if (this.moduleToReplace) {
        newModuleId = await this[types.REPLACE_MODULE]({
          canvasName: this.destinationCanvasName,
          payload: moduleToAdd,
          replacedModuleId: this.moduleToReplace.id,
        });
      } else {
        newModuleId = await this[types.ADD_MODULE]({
          canvasName: this.destinationCanvasName,
          payload: moduleToAdd,
        });
      }

      bus.emit('activeModule', {
        canvasName: this.destinationCanvasName,
        id: newModuleId,
      });
    },
    placeModule(mod) {
      if (this.moduleToReplace) {
        // If we have a module we're replacing, we already
        // know the destination coordinates
        this.addModuleToState(
          {
            ...mod,
            options: {
              ...this.moduleToReplace.options,
              ...mod.options,
            },
          },
          this.moduleToReplace.layout,
        );
      } else if (this.destinationCanvasName !== 'notes') {
        // Scan the grid if we're in the editor or modal canvas
        try {
          let coordinates;

          if (this.gridIsOff) {
            let canvasInfo;

            if (this.destinationCanvasName === 'modalModules') {
              canvasInfo = {
                width: MODAL_CANVAS_WIDTH,
                height: MODAL_CANVAS_HEIGHT,
                bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
              };
            } else {
              canvasInfo = {
                width: EDITOR_CANVAS_WIDTH,
                height: this.currentPage.options.height,
              };
            }

            canvasInfo.defaultPosition = this.getVisibleCanvasCenter(mod);

            coordinates = placeModuleWithoutGrid(
              this.modulesOrderedByZ,
              mod,
              canvasInfo,
            );
          } else {
            coordinates = this.scanGrid(this.gridMatrix, mod, this.draftType === 'board');
          }

          const layoutOverrides = {
            x_position: coordinates.x,
            y_position: coordinates.y,
          };

          if (this.gridIsOff) {
            // Assign the next z coordinate above other modules.
            layoutOverrides.z_position = createTopZPosition(this.modulesOrderedByZ);
          }

          this.addModuleToState(mod, layoutOverrides);
        } catch {
          // @TODO tie this into nr exception tracking so that it doesn't become a phantom error
          bus.emit('invalidPlacementAttempted');
        }
      } else {
        this.addModuleToState(
          mod,
          {
            x_position: mod.layout.x_position,
            y_position: bottomYPosition(get(this, 'currentPage.notes', [])),
          },
        );
      }

      // Close the menu now that the module has been added to the canvas
      this.menuVisible = false;
    },
    scanGrid,
  },
};
