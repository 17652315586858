<template>
  <BaseModal
    class="tei-image-zoom-modal"
    :id="id"
    :is-wide="false"
    header-margin="1x"
    heading=" "
    hide-footer
  >
    <img
      class="tei-image-zoom-modal__image"
      :src="options.imageUrl"
    >
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';

export default {
  name: 'TEIImageZoomModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="stylus">
.tei-image-zoom-modal {
  &__image {
    display: block;
    width: 100%;
  }

  .comet-modal__inner {
    max-width: 900px;
    min-width: 500px;
    width: calc(100vw - 500px);
  }
}
</style>
