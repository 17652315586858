<template>
  <nav
    v-if="totalPages > 1"
    aria-label="Pagination"
    class="studio-pagination"
    role="navigation"
  >
    <ul>
      <!-- Back button -->
      <li v-if="currentPage !== 1">
        <StudioPaginationItem
          :destination="formatDestination(currentPage - 1)"
          page-label="Back"
          :use-button="useButton"
          @click="onClickPage(currentPage - 1)"
        />
      </li>

      <!-- Always print first page -->
      <li>
        <StudioPaginationItem
          :destination="formatDestination(1)"
          :is-current-page="currentPage === 1"
          :page-label="1"
          :use-button="useButton"
          @click="onClickPage(1)"
        />
      </li>

      <!-- If there are 8 or less pages, print out all pages -->
      <template v-if="totalPages <= 8">
        <li
          v-for="i in range(2, totalPages)"
          :key="i"
        >
          <StudioPaginationItem
            :destination="formatDestination(i)"
            :is-current-page="currentPage === i"
            :page-label="i"
            :use-button="useButton"
            @click="onClickPage(i)"
          />
        </li>
      </template>

      <!-- If there are more than 8 pages, do more logic to determine what pages to show -->
      <template v-else>
        <!-- If current page is less than or equal to 5, show pages 2-8 and an ellipsis -->
        <template v-if="currentPage <= 5">
          <li
            v-for="i in range(2, 9)"
            :key="i"
          >
            <StudioPaginationItem
              :destination="formatDestination(i)"
              :is-current-page="currentPage === i"
              :page-label="i"
              :use-button="useButton"
              @click="onClickPage(i)"
            />
          </li>

          <li>
            <StudioPaginationItem page-label="Ellipsis" />
          </li>
        </template>

        <!--
          If current page not near the beginning or the end, show next and
          previous pages ranges, with the current page in the middle
        -->
        <template v-else-if="currentPage >= 6 && currentPage < totalPages - 7">
          <li>
            <StudioPaginationItem page-label="Ellipsis" />
          </li>

          <li
            v-for="i in range(currentPage - 3, currentPage + 4)"
            :key="i"
          >
            <StudioPaginationItem
              :destination="formatDestination(i)"
              :is-current-page="currentPage === i"
              :page-label="i"
              :use-button="useButton"
              @click="onClickPage(i)"
            />
          </li>

          <li>
            <StudioPaginationItem page-label="Ellipsis" />
          </li>
        </template>

        <!-- Else, show the last page range -->
        <template v-else>
          <li>
            <StudioPaginationItem page-label="Ellipsis" />
          </li>

          <li
            v-for="i in range(totalPages - 7, totalPages)"
            :key="i"
          >
            <StudioPaginationItem
              :destination="formatDestination(i)"
              :is-current-page="currentPage === i"
              :page-label="i"
              :use-button="useButton"
              @click="onClickPage(i)"
            />
          </li>
        </template>
      </template>

      <!-- Always print last page -->
      <li>
        <StudioPaginationItem
          :destination="formatDestination(totalPages)"
          :is-current-page="currentPage === totalPages"
          :page-label="totalPages"
          :use-button="useButton"
          @click="onClickPage(totalPages)"
        />
      </li>

      <!-- Next button -->
      <li v-if="currentPage !== totalPages">
        <StudioPaginationItem
          :destination="formatDestination(currentPage + 1)"
          page-label="Next"
          :use-button="useButton"
          @click="onClickPage(currentPage + 1)"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import StudioPaginationItem from '@/components/navigation/StudioPaginationItem.vue';

export default {
  name: 'StudioPagination',
  components: {
    StudioPaginationItem,
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    skipParam: {
      default: 'skip',
      type: String,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    useButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectPage'],
  methods: {
    formatDestination(page) {
      // Navigate to the same route with only the page changed
      return {
        query: {
          ...this.$route.query,
          [this.skipParam]: page,
        },
      };
    },
    onClickPage(page) {
      if (page !== this.currentPage) this.$emit('selectPage', page);
    },
    range(start, end) {
      // Return an array from start to end, similar to Jinja's range()
      // The start is included; end is not
      const pageRange = [];

      for (let i = start; i < end; i += 1) {
        pageRange.push(i);
      }

      return pageRange;
    },
  },
};
</script>

<style lang="stylus">
.studio-pagination {
  &__item {
    border-color: #c4c9d4; // matching DE search page
    border-radius: 0;
    height: 38px;

    &:hover, &:focus {
      background-color: $nebula-color-neutral-300;
      color: $nebula-color-interactive-blue-400;

      svg.nebula-button__icon {
        fill: $nebula-color-interactive-blue-400;
      }
    }

    &--static {
      background-color: $nebula-color-neutral-300;
      cursor: auto;

      &:hover, &:focus {
        background-color: $nebula-color-neutral-300;
        cursor: auto;
      }
    }

    &--current {
      background-color: $nebula-color-interactive-blue-300;
      color: $nebula-color-white;
      cursor: auto;

      &:hover {
        background-color: $nebula-color-interactive-blue-300;
        color: $nebula-color-white;
        cursor: auto;
      }
    }
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li:first-child .studio-pagination__item {
      border-bottom-left-radius: $nebula-border-radius-small;
      border-top-left-radius: $nebula-border-radius-small;
    }

    li:last-child .studio-pagination__item {
      border-bottom-right-radius: $nebula-border-radius-small;
      border-top-right-radius: $nebula-border-radius-small;
    }

    li:not(:last-child) .studio-pagination__item {
      border-right-width: 0;
    }
  }

  html[dir="rtl"] & {
    svg.nebula-button__icon {
      transform: scaleX(-1);
    }

    ul li {
      &:first-child .studio-pagination__item {
        border-radius: 0 $nebula-border-radius-small $nebula-border-radius-small 0;
      }

      &:last-child .studio-pagination__item {
        border-radius: $nebula-border-radius-small 0 0 $nebula-border-radius-small;
      }

      &:not(:last-child) .studio-pagination__item {
        border-left-width: 0;
        border-right-width: 1px;
      }
    }
  }
}
</style>
