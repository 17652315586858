import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';

const envRegexResults = /(.*?)(local|stage|qa|dev)\.discoveryeducation\.(com|co\.uk|ca)$/gi
  .exec(window.location.hostname);
const env = envRegexResults ? envRegexResults[2] : 'prod';

// These must be sent from the browser and therefore will be exposed no matter what.
// Since they are ingest keys, they only have permission to send events.
const honeycombApiKeys = {
  local: 'hcaik_01j7gxjfhhtfr30j43p0qfxwdas5k3aa0k089gx2xgp66msyfgb4ejash1',
  dev: 'hcaik_01j7gxe124fgxdeksy3h5vaetbg98y13pf3gx9q5gh8dzb598aebtjdv5j',
  qa: 'hcaik_01j7gy1yx5jra3m9jz753ywt65aq0h7ec3b870ncbw18a8w6v2rxg53pd0',
  stage: 'hcaik_01j7gy2m032p9d6g1w58fzgjchkg2te70rw7ngy7vqgjezc8q8wc5qt01r',
  prod: 'hcaik_01j7gy13f4a7hmr28jz1dthaa8ktbcmq29k5kmxresapszadjmbj8f8jyx',
};

// HoneycombWebSDK automatically includes instrumentations for Web Vitals
const sdk = new HoneycombWebSDK({
  apiKey: honeycombApiKeys[env],
  serviceName: 'studio-fe',
});

sdk.start();
