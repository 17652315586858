<template>
  <BaseModal
    class="tei-connection-card-modal"
    :class="{
      'tei-connection-card-modal--resource-url': options.resourceUrl,
      'tei-connection-card-modal--content': options.content,
    }"
    :id="id"
    :is-wide="false"
    header-margin="1x"
    heading=" "
    hide-footer
    v-if="!options.resourceUrl || this.image"
  >
    <div
      ref="scroll"
      class="tei-connection-card-modal__scroll"
      :class="{ 'tei-connection-card-modal__scroll--gutter': scrollbarGutter }"
      :style="scrollStyle"
    >
      <img
        v-if="options.resourceUrl"
        :src="options.resourceUrl"
        class="tei-connection-card-modal__image"
        :style="imageStyle"
      >
      <div
        class="tei-connection-card-modal__text"
        v-if="options.content"
      >
        <span
          class="tei-connection-card-modal__text-content"
          v-html="options.content"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { throttle } from 'lodash-es';
import BaseModal from '@/components/modals/BaseModal.vue';

/*
  Modal that is scaled as large as possible (or to the max image size configured) within
  the brower's viewport and also maintains an aspect-ratio that conforms to the resource
  image being displayed within.

  If text content is present, the modal will show the first line of text underneath the image
  and the additional lines can be seen by scrolling. Image and text content scroll together.
*/

// Min distance from edge of viewport
const MODAL_MARGIN = 32;
// Padding between content and the scrollbar
const SCROLL_PADDING = 4;
// Approximate width for scrollbar (ok if not exact)
const SCROLL_BAR_WIDTH = 17;
/*
  The min width allowed on the scrollable area if there is text to display.
  This is to prevent text from wrapping too much.
*/
const SCROLL_MIN_WIDTH = 300;
// Padding on modal inner content
const MODAL_PADDING = 24;
// Height of the header area plus the margin
const HEADER_HEIGHT = 32;

// Extra height to show first line of text. (lineHeight + margin + some extra padding)
const TEXT_HEIGHT = 54;
// Total modal height (with margin) minus the height of the scrollable area
const MODAL_EMPTY_HEIGHT = MODAL_MARGIN + MODAL_PADDING + HEADER_HEIGHT
  + MODAL_PADDING + MODAL_MARGIN;
// Total modal width (with margin) minus the width of the scrollable area
const MODAL_EMPTY_WIDTH = MODAL_MARGIN + MODAL_PADDING + SCROLL_PADDING
  + SCROLL_BAR_WIDTH + MODAL_PADDING + MODAL_MARGIN;
// Minimum width allowed for the modal
const MODAL_MIN_WIDTH = MODAL_MARGIN + MODAL_PADDING + SCROLL_MIN_WIDTH
  + MODAL_PADDING + MODAL_MARGIN;
// Max size allowed for image in the modal.
const IMAGE_MAX_HEIGHT = 425;
const IMAGE_MAX_WIDTH = 900;

export default {
  name: 'TEIConnectionCardModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      image: null,
      windowSize: null,
      scrollbarGutter: false,
    };
  },
  created() {
    if (this.options.resourceUrl) {
      const img = new Image();
      img.onload = () => {
        this.image = {
          width: img.width,
          height: img.height,
          aspectRatio: img.width / img.height,
        };
        // Set the max size we will allow when displaying the resource image.
        this.image.maxSize = (IMAGE_MAX_WIDTH / IMAGE_MAX_HEIGHT) < this.image.aspectRatio
          ? { width: IMAGE_MAX_WIDTH, height: IMAGE_MAX_WIDTH / this.image.aspectRatio }
          : { width: IMAGE_MAX_HEIGHT * this.image.aspectRatio, height: IMAGE_MAX_HEIGHT };

        this.setScrollbarGutter();
      };
      img.src = this.options.resourceUrl;
      this.onWindowResize();
      this.throttleOnWindowResize = throttle(this.onWindowResize, 10);
      window.addEventListener('resize', this.throttleOnWindowResize);
    }
  },
  mounted() {
    this.setScrollbarGutter();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.throttleOnWindowResize);
  },
  methods: {
    setScrollbarGutter() {
      /*
        Workaround for a bug in firefox where the scrollbar overlays the image.
        Setting scrollbar-gutter to 'stable' forces the scrollbar to its correct position.
      */
      this.$nextTick(() => {
        const { scroll } = this.$refs;
        if (!scroll) return;
        this.scrollbarGutter = navigator.userAgent.toLowerCase().includes('firefox')
          && this.options.content && this.options.resourceUrl
          && scroll.scrollHeight > scroll.clientHeight;
      });
    },
    onWindowResize() {
      this.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    canFitImage(size) {
      /*
        Check if the modal will fit within the viewport when the specified image
        size is used while leaving room for the first line of text.
      */
      const modalHeight = size.height + MODAL_EMPTY_HEIGHT + this.textHeight;
      let modalWidth = size.width + MODAL_EMPTY_WIDTH;
      // Enforce a minimum width if there is text content to avoid too much text wrapping
      if (this.options.content && modalWidth < MODAL_MIN_WIDTH) modalWidth = MODAL_MIN_WIDTH;
      return modalHeight <= this.windowSize.height && modalWidth <= this.windowSize.width;
    },
    getImageSize(size) {
      // If size if greater than max, return the max size, otherwise return the size.
      return (size.height > IMAGE_MAX_HEIGHT || size.width > IMAGE_MAX_WIDTH)
        ? this.image.maxSize
        : size;
    },
  },
  computed: {
    scrollStyle() {
      return this.imageSize
        ? {
          height: `${this.imageSize.height + this.textHeight}px`,
        } : null;
    },
    imageStyle() {
      return this.imageSize
        ? {
          width: `${this.imageSize.width}px`,
          height: `${this.imageSize.height}px`,
        } : null;
    },
    textHeight() {
      return this.options.content ? TEXT_HEIGHT : 0;
    },
    imageSize() {
      if (!this.image || !this.windowSize) return null;
      /*
        Size the image as large as we can (or max size allowed), but leave enough room
        for the first line of text to display underneath. Both the image and text will
        scroll together in order to see additional lines of text.
      */
      const height = this.windowSize.height - MODAL_EMPTY_HEIGHT - this.textHeight;
      let size = this.getImageSize({ height, width: this.image.aspectRatio * height });
      if (!this.canFitImage(size)) {
        const width = this.windowSize.width - MODAL_EMPTY_WIDTH;
        size = this.getImageSize({ width, height: width / this.image.aspectRatio });
      }
      return size;
    },
  },
};
</script>

<style lang="stylus">
@import '../teis/styles/tei-fonts';

.tei-connection-card-modal {
  &__scroll {
    overflow-x: hidden;
    overflow-y: auto;
    padding-inline-end: $nebula-space-half;
    width: 100%;

    &--gutter {
      scrollbar-gutter: stable;
    }
  }

  &__text {
    tei-mikado-body-primary-regular();
    display: flex;
    margin-top: $nebula-space-2x;
    padding-left: $nebula-space-1x;
    padding-right: $nebula-space-1x;

    &-content {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    border-radius: $nebula-border-radius-default;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &--content {
    .tei-connection-card-modal__scroll {
      min-width: 300px;
    }
  }

  .comet-modal {
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    width: min-content;
  }

  .comet-modal__inner {
    width: 100%;
  }

  &:not(.tei-connection-card-modal--resource-url) {
    .tei-connection-card-modal__text {
      tei-mikado-body-primary-medium();
    }

    .tei-connection-card-modal__scroll {
      max-height: calc(100vh - 180px);
    }

    .comet-modal {
      width: 500px;
    }
  }
}
</style>
