<template>
  <BaseModal
    :id="id"
    class="search-modal"
    :heading="$t('Discovery Education Search')"
    hide-header-cancel-button
    :initial-focus="() => (previousFocus || $refs.search?.$el)"
    :focus-trap-active="!nestedModalIsOpen"
    :on-close="cancel"
  >
    <form
      class="search-modal__input-wrapper"
      @submit.prevent="onSearch"
    >
      <NebulaInput
        ref="search"
        class="search-modal__input"
        :placeholder="$t('Search...')"
        :text="searchField"
        type="text"
        @input="searchField = $event"
      />
      <NebulaButton
        :aria-label="$t('Search')"
        class="search-modal__button"
        icon-left="search"
        is-submit
        size="l"
        type="flat"
      />
    </form>
    <div class="search-modal__filters-wrapper">
      <div class="search-modal__language-grade">
        <NebulaDropdown
          ref="languageDropdown"
          button-id="language-menu"
        >
          <template
            #trigger="{
              toggleDropdown,
              checkArrowKeyOnTrigger,
              checkArrowKeyAfterTrigger,
            }"
          >
            <NebulaButton
              id="language-menu"
              :text="languageDropdownItems.find(i => i.selected).label"
              icon-right="caret-down"
              haspopup="true"
              @click="toggleDropdown"
              @keydown="checkArrowKeyOnTrigger"
              @keyup="checkArrowKeyAfterTrigger"
            />
          </template>
          <template #content>
            <NebulaDropdownItem
              v-for="item in languageDropdownItems"
              :key="item.label"
              :text="item.label"
              @click="updateSelectedLanguage(item)"
            />
          </template>
        </NebulaDropdown>

        <div
          v-if="showGradeBands"
          class="search-modal__grade-bands"
        >
          <input
            id="search-grade-filter-k-2"
            type="checkbox"
            v-model="filters['K-2']"
            @change="updateGradeBand"
          >
          <label
            class="nebula-button nebula-button--pill nebula-button--ghost nebula-button--s"
            role="button"
            for="search-grade-filter-k-2"
          >
            K–2
          </label>

          <input
            id="search-grade-filter-3-5"
            type="checkbox"
            v-model="filters['3-5']"
            @change="updateGradeBand"
          >
          <label
            class="nebula-button nebula-button--pill nebula-button--ghost nebula-button--s"
            role="button"
            for="search-grade-filter-3-5"
          >
            3–5
          </label>

          <input
            id="search-grade-filter-6-8"
            type="checkbox"
            v-model="filters['6-8']"
            @change="updateGradeBand"
          >
          <label
            class="nebula-button nebula-button--pill nebula-button--ghost nebula-button--s"
            role="button"
            for="search-grade-filter-6-8"
          >
            6–8
          </label>

          <input
            id="search-grade-filter-9-12"
            type="checkbox"
            v-model="filters['9-12']"
            @change="updateGradeBand"
          >
          <label
            class="nebula-button nebula-button--pill nebula-button--ghost nebula-button--s"
            role="button"
            for="search-grade-filter-9-12"
          >
            9–12
          </label>
        </div>
      </div>
    </div>
    <div class="search-modal__tabs comet-tabs comet-tabs--horizontal-sliding">
      <div class="comet-tabs__horizontal-sliding-outer-wrap">
        <div class="comet-tabs__horizontal-sliding-wrap">
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': !selectedTabs.length }"
            @click="onSelectTab(null)"
          >
            <span class="comet-tab__label-text">{{ $t('All') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('video') }"
            @click="onSelectTab('video')"
          >
            <span class="comet-tab__label-text">{{ $t('Video') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('activity') }"
            @click="onSelectTab('activity')"
          >
            <span class="comet-tab__label-text">{{ $t('Activities') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('image') }"
            @click="onSelectTab('image')"
          >
            <span class="comet-tab__label-text">{{ $t('Images') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('audio') }"
            @click="onSelectTab('audio')"
          >
            <span class="comet-tab__label-text">{{ $t('Audio') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('text') }"
            @click="onSelectTab('text')"
          >
            <span class="comet-tab__label-text">{{ $t('Text') }}</span>
          </button>
          <button
            class="comet-tab__label"
            :class="{ 'comet-tab__label--selected': selectedTabs.includes('interactive') }"
            @click="onSelectTab('interactive')"
          >
            <span class="comet-tab__label-text">{{ $t('Interactives') }}</span>
          </button>
        </div>
      </div>
    </div>
    <LoadingIndicator
      v-if="searching"
      class="search-loading"
      small
      transparent
    />
    <template v-if="results && !searching">
      <div
        class="search-modal__results paging"
        v-if="!searching && paging.page >= 0"
      >
        <p
          v-if="paging.total_pages === 0"
          class="search-modal__pagination-message"
        >
          {{ $t('No results.') }}
        </p>
        <p
          v-else
          class="search-modal__pagination-message"
        >
          {{ $t('Viewing page %(page)s of %(total_pages)s', {
            page: paging.page,
            total_pages: paging.total_pages,
          }) }}
        </p>
      </div>
      <ul
        class="search-modal__results comet-cards"
        v-if="!searching"
      >
        <li
          v-for="asset in results"
          :key="asset.id"
          class="nebula-card nebula-card--select"
          :class="{ 'nebula-card--active': isSelected(asset.id) }"
        >
          <input
            type="checkbox"
            class="nebula-screenreader-only"
            :checked="isSelected(asset.id)"
            :value="asset.id"
            :title="asset.title"
            :id="`search-modal-asset-${asset.id}`"
            @change="onSelectAsset($event, asset)"
          >
          <label
            class="search-modal__result-label"
            :for="`search-modal-asset-${asset.id}`"
          >
            <AssetCard
              :asset="asset"
              @set-previous-focus="previousFocus = $event"
            />
            <div class="search-modal__checked-icon">
              <NebulaIcon
                v-if="isSelected(asset.id)"
                symbol-id="circle-check--filled"
                size="l"
              />
            </div>
          </label>
        </li>
        <!-- Intentional flex spacers -->
        <li class="nebula-card nebula-card--invisible" />
        <li class="nebula-card nebula-card--invisible" />
        <li class="nebula-card nebula-card--invisible" />
      </ul>
    </template>
    <template #footer>
      <div class="comet-modal__footer">
        <div class="comet-modal__button-row search-modal__button-row">
          <StudioPagination
            v-if="paging.page"
            class="search-modal__pagination"
            :current-page="paging.page"
            :total-pages="paging.total_pages"
            use-button
            @selectPage="onSelectPage"
          />
          <div class="search-modal__button-wrapper">
            <button
              class="nebula-button nebula-button--ghost nebula-button--s comet-modal__button
                comet-modal__button--cancel"
              @click="cancel"
            >
              {{ $t('Cancel') }}
            </button>
            <button
              class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
              :class="{
                'nebula-button--disabled': assetSelection.length < 1,
              }"
              :disabled="assetSelection.length < 1"
              @click="onConfirm"
            >
              {{ $t('Add Selected') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import {
  NebulaButton,
  NebulaDropdown,
  NebulaDropdownItem,
  NebulaIcon,
  NebulaInput,
} from '@discoveryedu/nebula-components';
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import AssetCard from '@/components/ui-elements/AssetCard.vue';
import StudioPagination from '@/components/navigation/StudioPagination.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { isGuid } from '@/lib/utils';
import assetSelectionModal from '@/mixins/assetSelectionModal';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
  useUserStore,
} from '@/stores';

export default {
  name: 'SearchAssets',
  components: {
    AssetCard,
    BaseModal,
    StudioPagination,
    LoadingIndicator,
    NebulaIcon,
    NebulaInput,
    NebulaButton,
    NebulaDropdown,
    NebulaDropdownItem,
  },
  mixins: [assetSelectionModal], // handles selecting assets and adding to the canvas
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
  },
  data() {
    return {
      filters: {
        'K-2': false,
        '3-5': false,
        '6-8': false,
        '9-12': false,
      },
      languageDropdownItems: [
        {
          label: this.$t('All Languages'),
          selected: true,
        }, {
          label: this.$t('English'),
          language_code: 'eng',
        }, {
          label: this.$t('Spanish'),
          language_code: 'spa',
        }, {
          label: this.$t('French'),
          language_code: 'fre',
        },
      ],
      searchField: '',
      selectedTabs: [],
      results: null,
      paging: {},
      pageLimit: 9,
      selectedPage: null,
      searching: false,
      // for keeping track of where to restore focus to when a submodal is closed
      previousFocus: null,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      'user',
      'isDeUser',
      'userHasAnyRole',
    ]),
    ...mapState(useModalStore, [
      'modals',
      'myContentModalOptions',
    ]),
    nestedModalIsOpen() {
      return this.modals.find((modal) => modal.type === 'AssetShareModal')
        || get(this, 'myContentModalOptions.config.isActive');
    },
    showGradeBands() {
      // For now, grade bands are hidden for UK and Sweden
      const countryCode = get(this.user, 'analytics_data.site.country_code');
      if (countryCode === 'gb' || countryCode === 'se') return false;

      return true;
    },
  },
  created() {
    this.onSearch();
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    cancel() {
      // If the share modal or My Content modal is open, don't close
      // this modal
      if (!this.nestedModalIsOpen) {
        this[types.CLOSE_MODAL]({ id: this.id });
      }
    },
    onSelectPage(page) {
      this.selectedPage = page;
      this.submitSearch();
    },
    updateSelectedLanguage(selectedItem) {
      const dropdownRef = this.$refs?.languageDropdown;
      if (dropdownRef) dropdownRef.toggleDropdown();

      this.languageDropdownItems = this.languageDropdownItems.map((item) => ({
        ...item,
        selected: item.label === selectedItem.label,
      }));

      this.selectedPage = null; // reset to the first page when language is changed
      this.submitSearch();
    },
    onSearch() {
      // Reset to the first page when the query is changed, and then search
      this.selectedPage = null;
      this.submitSearch();
    },
    async submitSearch() {
      // set searching flag
      this.searching = true;

      // guid search
      if (isGuid(this.searchField)) {
        // Make our api call
        const apiResults = await this.$deApi.get(`/assets/${this.searchField}`);

        // Allow editors to add assets that don't exist yet
        if ((get(apiResults, 'response.status') === 404) && this.isDeUser) {
          this.results = [{
            id: this.searchField,
            thumbnails: [],
            title: this.$t('404 Asset Not Found'),
            type: {},
          }];
        } else {
          this.results = [apiResults.data.asset];
        }
        this.selectedPage = null;
        this.paging = {};
      } else {
        // Prepare our data
        const selectedLanguage = this.languageDropdownItems
          .find((language) => language.selected).language_code;

        // get grade bands
        const selectedGradeBands = Object.entries(this.filters) // returns entries as [key, value]
          .filter((entry) => entry[1]) // filter to selected grade bands
          .map((entry) => entry[0]); // map to key names (e.g. '3-5')

        // Make our api call
        const apiResults = await this.$deApi.post(
          '/search/assets',
          {
            language_code: selectedLanguage,
            q: this.searchField,
            grade_band: selectedGradeBands,
            group: this.selectedTabs,
            page: this.selectedPage || 1,
            per_page: 12,
          },
        );

        if (apiResults.data) {
          this.results = apiResults.data.assets;
          this.paging = apiResults.data.paging;

          // use the given page as long as its not larger than the number of pages
          this.selectedPage = Math.min(
            apiResults.data.paging.page,
            apiResults.data.paging.total_pages,
          );
        }
      }

      this.searching = false;
      return true;
    },
    onSelectTab(newTab) {
      this.selectedTabs = newTab ? [newTab] : [];
      this.selectedPage = null; // reset to the first page when tabs are changed
      this.submitSearch();
    },
    updateGradeBand() {
      // Reset to the first page when grade band is changed, and then search
      this.selectedPage = null;
      this.submitSearch();
    },
  },
};
</script>

<style lang="stylus">
.search-modal {
  .comet-modal {
    width: 100%;
  }

  .comet-modal__inner {
    display: flex;
    flex-direction: column;
    height: "calc(100vh - %s)" % $nebula-space-4x; // match the outer max-height

    > * {
      flex-shrink: 0;
    }
  }

  .comet-long-form-text {
    height: auto;
    overflow: visible;

    h3 {
      text-align: center;
    }
  }

  .comet-modal__footer {
    margin: 0;
  }

  @media screen and (max-height: $comet-breakpoints-tablet-portrait) {
    // For very short screens (e.g. 16:9 chromebook), don't allow an extra-wide layout
    .comet-modal__inner {
      max-width: 1000px;
    }
  }

  &__input-wrapper {
    align-self: center;
    margin: 0 0 $nebula-space-2x 0;
    max-width: 700px; // match input
    position: relative;
    width: 100%;

    .search-modal__input {
      margin: 0;
      max-width: none;
      padding-inline-end: 56px; // width of button
    }

    .search-modal__button {
      border-end-end-radius: 7px; // fit inside the 1px border, which has 8px radius
      border-end-start-radius: 0;
      border-start-end-radius: 7px;
      border-start-start-radius: 0;
      bottom: 1px;
      inset-inline-end: 1px;
      margin: 0;
      position: absolute;
      top: 1px;
    }
  }

  &__filters-wrapper {
    align-items: center;
    align-self: center;
    display: flex;
    margin: 0 0 $nebula-space-1x 0;

    > div {
      display: flex;
    }

    .search-modal__grade-button:last-child {
      margin: 0;
    }
  }

  &__tabs {
    .comet-tab__label {
      background: none;
      border: none;
      border-radius: 0;
    }

    .comet-tabs__horizontal-sliding-wrap {
      text-align: center;
    }
  }

  .search-modal__results {
    overflow: auto;
    margin-top: $nebula-space-2x;

    &.comet-cards {
      flex-grow: 1;
      flex-shrink: 1;
      margin: $nebula-space-2x $nebula-space-2x * -1 0;
      padding-inline-start: $nebula-space-2x;
    }

    > .nebula-card {
      box-shadow: none;
      margin-inline-end: $nebula-space-2x;
    }

    .nebula-card {
      overflow: visible;
      position: relative;

      &.nebula-card--active {
        box-shadow: 0 0 0 2px $nebula-color-interactive-blue-300;
      }

      &--invisible {
        box-shadow: none;
        opacity: 0;
      }
    }

    .search-modal__checked-icon {
      padding-top: 56.25%; // position in the center of the 16/9 thumbnail
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      &:after {
        background-color: $nebula-color-white;
        border-radius: 50%;
        bottom: 2px;
        content: '';
        display: block;
        left: 5%;
        padding-top: 90%;
        position: absolute;
        width: 90%;
      }

      svg {
        display: block;
        fill: $nebula-color-interactive-blue-300;
        position: relative;
        z-index: 1;
      }
    }
  }

  &__result-label {
    cursor: pointer;
    flex-grow: 1;
  }

  &__pagination-message {
    margin: 0;
  }

  &__pagination {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    margin-top: $nebula-space-2x;

    ul {
      margin: 0;
    }
  }

  .search-loading {
    padding: 20px;
  }

  &__button-row {
    align-items: flex-end;
    flex-wrap: wrap;
  }

  &__button-wrapper {
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: $nebula-space-2x;
    justify-content: flex-end;
    margin-top: $nebula-space-2x;
  }

  &__grade-bands {
    align-items: center;
    display: flex;
    justify-content: center;

    input {
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      order: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;

      &:checked + .nebula-button {
        background-color: $nebula-color-interactive-blue-300;
        color: $nebula-color-white;
      }

      &:focus + .nebula-button {
        outline: auto;
        outline: auto 5px -webkit-focus-ring-color;
      }
    }

    label {
      margin-inline-start: $nebula-space-2x;
    }
  }

  html[dir="rtl"] & {
    &__button svg {
      transform: scaleX(-1);
    }

    .search-modal__language-grade .nebula-button__text {
      margin-inline: 0 $nebula-space-1x;
    }
  }
}
</style>
