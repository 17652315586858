<template>
  <div class="invite-form">
    <div class="invite-form__form-wrap">
      <div class="invite-form__invitee-area">
        <NebulaIcon
          symbol-id="search"
          class="invite-form__search-icon"
          size="s"
        />
        <NebulaInput
          ref="inviteeInput"
          id="invite-form__invitee-input"
          class="invite-form__invitee-input"
          autocomplete="off"
          role="searchbox"
          aria-haspopup="listbox"
          aria-autocomplete="list"
          :aria-activedescendant="focusedAutocompleteSuggestion"
          :aria-expanded="flattenedSuggestions.length"
          :placeholder="$t('Search for people or classrooms in your school')"
          :text="inputText"
          type="text"
          @input="onInput"
          @keydown.prevent.down="focusNextSuggestion"
          @keydown.prevent.up="focusPrevSuggestion"
        />
      </div>
      <div
        ref="autocompleteDropdown"
        class="comet-dropdown-menu comet-popover invite-autocomplete"
        :class="{ visible: flattenedSuggestions.length }"
        v-show="flattenedSuggestions.length"
        :aria-hidden="!flattenedSuggestions.length"
      >
        <div
          class="comet-list-group comet-dropdown-menu__list-group
            comet-list-group--no-hairlines"
        >
          <ul class="comet-list-group__list">
            <li
              v-for="suggestion in flattenedSuggestions"
              :key="suggestion.id"
              class="comet-list-group__row invite-autocomplete__item"
            >
              <!-- Note: These list options are navigable with arrow keys -->
              <div class="comet-list-group__row-anchor">
                <span
                  class="share-avatar"
                  :class="`share-avatar--${suggestion.domain}`"
                >
                  <template v-if="suggestion.domain === 'user'">
                    {{ toInitial(suggestion.first_name) }}{{ toInitial(suggestion.last_name) }}
                  </template>
                  <template v-else>
                    {{ toInitial(suggestion.name) }}
                  </template>
                </span>
                <div class="comet-list-group__row-label invite-autocomplete__row-label">
                  <template v-if="suggestion.domain === 'user'">
                    {{ suggestion.first_name }} {{ suggestion.last_name }}
                  </template>
                  <template v-else>
                    {{ suggestion.name }}
                  </template>
                  <br>
                  <span class="invite-autocomplete__type">
                    {{ suggestion.formattedType }}
                  </span>
                </div>
                <button
                  :id="`share-suggestion-${suggestion.id}`"
                  :aria-describedby="`invite-form__aria-description--${suggestion.id}`"
                  ref="suggestions"
                  class="comet-button comet-button--ghost
                    comet-button--dark invite-form__add-button"
                  @click="selectAutocompleteSuggestion(suggestion)"
                  @focus="onFocusAutocompleteSuggestion(suggestion.id)"
                  @keydown.prevent.down="focusNextSuggestion"
                  @keydown.prevent.up="focusPrevSuggestion"
                >
                  {{ $t('Add') }}
                  <span
                    :id="`invite-form__aria-description--${suggestion.id}`"
                    class="invite-form__aria-description"
                  >
                    {{ suggestion.domain === 'user'
                      ? $t('%(firstName)s %(lastName)s %(type)s', {
                        firstName: suggestion.first_name,
                        lastName: suggestion.last_name,
                        type: suggestion.formattedType })
                      : $t('%(name)s %(type)s', {
                        name: suggestion.name,
                        type: suggestion.formattedType }) }}
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NebulaIcon, NebulaInput } from '@discoveryedu/nebula-components';
import { throttle } from 'lodash-es';
import { CancelToken, isCancel } from 'axios';
import { sharingTypes } from '@/lib/constants';
import { isGuid, toInitial } from '@/lib/utils';

export default {
  name: 'InviteForm',
  components: {
    NebulaIcon,
    NebulaInput,
  },
  props: {
    shares: {
      type: Object,
      required: true,
    },
  },
  emits: ['add-invitee'],
  data() {
    return {
      autocompleteSuggestions: null,
      autocompleteCancelToken: null,
      focusedAutocompleteSuggestion: null,
      inputText: '',
      sharingTypes,
      shareTypeLabels: {
        classrooms: {
          label: this.$t('Classroom'), // human-readable label
          domain: 'classroom', // edde key for share domain
        },
        groups: {
          label: this.$t('Group'),
          domain: 'group',
        },
        users: {
          label: this.$t('User'),
          domain: 'user',
        },
      },
    };
  },
  computed: {
    flattenedSuggestions() {
      if (!this.autocompleteSuggestions) return [];

      // Flatten and order the nested suggestions object,
      // removing any suggestions that we've already selected to avoid duplicates
      return ['users', 'classrooms', 'groups'].reduce((acc, type) => {
        const suggestions = this.autocompleteSuggestions[type];
        const formattedSuggestions = suggestions
          .map((suggestion) => ({
            ...suggestion,
            domain: this.shareTypeLabels[type].domain,
            formattedType: this.shareTypeLabels[type].label,
            type,
          }))
          .filter((suggestion) => !this.unselectableIds.includes(suggestion.id));
        return acc.concat(formattedSuggestions);
      }, []);
    },
    unselectableIds() {
      // Ids that we've already shared the creation should not appear in the suggestions list
      return ['user', 'classroom', 'group'].reduce((acc, domain) => {
        if (!this.shares[domain]) return acc;
        return acc.concat(this.shares[domain].map((share) => share.owner_guid));
      }, []);
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.onBodyClick);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.onBodyClick);
  },
  methods: {
    focusNextSuggestion({ target }) {
      if (!this.$refs.suggestions || !this.$refs.suggestions.length) return;

      const currentIndex = this.$refs.suggestions.indexOf(target);

      if (currentIndex === -1 || currentIndex + 1 >= this.$refs.suggestions.length) {
        // If we're not in the list or are on the last item, focus the first item
        this.$refs.suggestions[0].focus();
      } else {
        // Otherwise focus the next item
        this.$refs.suggestions[currentIndex + 1].focus();
      }
    },
    focusPrevSuggestion({ target }) {
      if (!this.$refs.suggestions || !this.$refs.suggestions.length) return;

      const currentIndex = this.$refs.suggestions.indexOf(target);

      if (currentIndex - 1 < 0) {
        // If we're not in the list or are on the first item, focus the last item
        this.$refs.suggestions[this.$refs.suggestions.length - 1].focus();
      } else {
        // Otherwise focus the previous item
        this.$refs.suggestions[currentIndex - 1].focus();
      }
    },
    onBodyClick({ target }) {
      // Close the autocomplete dropdown if we click outside
      // (e.g. elsewhere in the modal)
      if (!this.$refs.inviteeInput.$el.contains(target)
        && !this.$refs.autocompleteDropdown.contains(target)) {
        this.autocompleteSuggestions = null;
      }
    },
    onFocusAutocompleteSuggestion(id) {
      this.focusedAutocompleteSuggestion = `share-suggestion-${id}`;
    },
    onInput(text) {
      this.inputText = text;
      this.throttledUpdateAutocomplete(text);
    },
    selectAutocompleteSuggestion(suggestion) {
      // Move suggestion from this form up into the main shares area of the modal,
      // which will also remove the suggestion from the list
      this.$emit('add-invitee', {
        ...suggestion,
        type: 'view',
      });
    },
    throttledUpdateAutocomplete: throttle(async function updateAutocomplete(text) {
      // If we've already made a request, kill it
      if (this.autocompleteCancelToken) {
        this.autocompleteCancelToken.cancel();
        this.autocompleteCancelToken = null;
      }

      const searchQuery = text;

      if (searchQuery.length < 2) {
        this.autocompleteSuggestions = null;
      } else {
        try {
          this.autocompleteCancelToken = CancelToken.source();

          if (isGuid(searchQuery)) {
            // If we're searching with a guid, get that user directly
            await this.$deApi.get(
              `users/${searchQuery}`,
              { cancelToken: this.autocompleteCancelToken.token },
            ).then(({ data }) => {
              this.autocompleteSuggestions = {
                classrooms: [],
                groups: [],
                users: [data.user],
              };
            });
          } else {
            // Otherwise use a text query
            await this.$deApi.get(
              `/directory/suggestions?search_text=${searchQuery}&limit=5`,
              { cancelToken: this.autocompleteCancelToken.token },
            ).then(({ data }) => {
              this.autocompleteSuggestions = data;
            });
          }
        } catch (error) {
          if (!isCancel(error)) {
            this.autocompleteSuggestions = null;
          }
        }
      }
    }, 300),
    toInitial,
  },
};
</script>

<style lang="stylus">
.invite-form {
  margin: 0 0 $nebula-space-2x 0;

  &__form-wrap { // .invite-form__form-wrap
    display: flex;
    margin: 0 0 $nebula-space-1x;
    position: relative;
    width: 100%;
  }

  &__search-icon { // .invite-form__search-icon
    inset-inline-start: $nebula-space-1x + 1; // account for 1px border
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__invitee-area { // .invite-form__invitee-area
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  &__invitee { // .invite-form__invitee
    border: $comet-border-hairline-default;
    border-radius: 2px;
    margin-block: 0 $nebula-space-1x;
    margin-inline: 0 $nebula-space-2x;
    padding: $nebula-space-1x;
  }

  &__remove-invitee-button { // .invite-form__remove-invitee-button
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  &__remove-invitee-icon.comet-icon--l { // .invite-form__remove-invitee-icon.comet-icon--l
    fill: $comet-color-neutral-60;
    height: 12px;
    width: 12px;
  }

  &__invitee-input.nebula-input {
    margin: 0;
    min-width: 100%;
    padding-inline: $nebula-space-4x $nebula-space-2x;
    width: 100%;
  }

  &__invitee-permissions { // .invite-form__invitee-permissions
    padding-top: 6px;
  }

  &__add-button.comet-button { // .invite-form__add-button.comet-button
    margin-block: 0;
    margin-inline: $nebula-space-1x 0;
    padding: $nebula-space-1x $nebula-space-2x;
  }

  &__aria-description {
    display: none;
  }
}

.invite-autocomplete {
  &.comet-dropdown-menu { // for specificity's sake
    max-width: none;
    top: 100%;
    width: 100%;
  }

  &__item.comet-list-group__row { // .invite-autocomplete__item.comet-list-group__row
    align-items: center;
    background: none;
    border: none;
    display: flex;
    text-align: start;

    &:not(:last-child) {
      border-bottom: $comet-border-hairline-default;
    }

    &:hover, &:focus, &:active {
      background: none;
    }
  }

  &__row-label.comet-list-group__row-label {
    line-height: 1;
    padding-inline-start: $nebula-space-1x;
  }

  &__type { // .invite-autocomplete__type
    color: $comet-text-color-secondary-on-light;
    font-size: $comet-font-size-metadata;
  }
}
</style>
