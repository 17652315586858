<template>
  <AppMessage
    :heading="$t('Deleted Creation')"
    :hide-header-cancel-button="true"
    :show-icon="false"
    :action-button-text="$t('Restore')"
    :disable-action-button="restoring"
    :show-action-button="!!options.onRestore"
    @action-button-click="restore"
  >
    <CometBlockMessage
      block-message-type="error"
      v-if="error"
    >
      <p>{{ error }}</p>
    </CometBlockMessage>
    {{ $t(
      'This "%(name)s" is in the trash.',
      {
        name: decodeHTML(options.creation.name),
        interpolation: { escapeValue: false },
      })
    }}
    <template v-if="!!options.onRestore">
      {{ $t('Did you want to restore it?') }}
    </template>
  </AppMessage>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions } from 'pinia';
import { decodeHTML } from '@/lib/utils';
import * as types from '@/lib/constants/store';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import {
  useEditorStore,
} from '@/stores';
import AppMessage from './AppMessage.vue';

export default {
  name: 'DeletedCreationMessage',
  components: {
    AppMessage,
    CometBlockMessage,
  },
  data() {
    return {
      restoring: false,
      error: null,
      decodeHTML,
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.RESTORE_CREATION,
    ]),
    async restore() {
      try {
        this.restoring = true;
        this.error = null;

        await this[types.RESTORE_CREATION]({
          creation: this.options.creation,
          type: this.options.dataType,
        });

        if (this.options.onRestore) {
          this.options.onRestore();
        }
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e.message);
        this.restoring = false;
      }
    },
  },
};
</script>
