<template>
  <div
    class="comet-block-message"
    :class="blockMessageClassModifier"
  >
    <div
      v-if="showIcon"
      class="comet-block-message__icon-box"
    >
      <NebulaIcon
        class-override="comet-block-message__icon"
        :symbol-id="blockMessageSymbolId"
        :role="blockMessageType === 'error' ? 'alert' : null"
      />
    </div>
    <div class="comet-block-message__content comet-long-form-text">
      <slot />
    </div>
    <slot name="button" />
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
  name: 'CometBlockMessage',
  components: {
    NebulaIcon,
  },
  props: {
    blockMessageType: {
      type: String,
      default: 'informational', // ['success', 'warning', 'error', informational']
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    blockMessageSymbolId() {
      if (this.blockMessageType === 'success') return 'badge-correct';
      if (this.blockMessageType === 'warning') return 'message-warning';
      if (this.blockMessageType === 'error') return 'message-error';
      return 'message-information';
    },
    blockMessageClassModifier() {
      return `comet-block-message--${this.blockMessageType}`;
    },
  },
};
</script>
