<template>
  <BaseModal
    :id="id"
    hide-footer
    :heading="$t('Are you sure you want to leave this page?')"
    :initial-focus="() => $refs.confirmButton"
    class="confirm-navigate-away-modal"
  >
    <p>
      {{ $t('You will be leaving Discovery Education.') }}
    </p>
    <template #footer>
      <div
        class="comet-modal__footer confirm-navigate-away-buttons
          confirm-navigate-away-buttons--cancel-align-left modal__footer--margin-3x"
      >
        <div class="comet-modal__button-row">
          <button
            class="nebula-button nebula-button--ghost comet-modal__button nebula-button--s
              comet-modal__button--cancel confirm-navigate-away-buttons__cancel-button"
            @click="onClose"
          >
            {{ $t('Stay on This Page') }}
          </button>
          <button
            ref="confirmButton"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button
              confirm-navigate-away-buttons__confirm-button"
            @click="options.onConfirm"
          >
            <span class="comet-button__inner">
              <span class="comet-button__text">
                {{ $t('Leave This Page') }}
              </span>
              <NebulaIcon
                class-override="comet-button__icon comet-button__icon--right"
                symbol-id="arrow-right"
              />
            </span>
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { mapActions } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
} from '@/stores';

export default {
  components: {
    BaseModal,
    NebulaIcon,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    onClose() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
  },
};
</script>

<style lang="stylus">
.confirm-navigate-away-modal.modal--base.modal--studio {
  .page-content--contain-within & {
    // Fix display issue with fullscreen in Techbook
    position: absolute;
  }
}

.confirm-navigate-away-buttons--cancel-align-left {
  .confirm-navigate-away-buttons__cancel-button {
    margin: 0 auto 0 0;
  }
}

.confirm-navigate-away-buttons__confirm-button {
  svg {
    fill: $nebula-color-white;
  }
}
</style>
