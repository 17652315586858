<template>
  <BaseModal
    :id="id"
    :disable-confirm-button="restoring"
    :disable-cancel-button="restoring"
    :close-on-backdrop-click="!restoring"
    :is-wide="false"
    :on-confirm="confirmRestore"
    :heading="$t('This Creation is in Trash')"
    :confirm-text="$t('Move to My Studio')"
    :cancel-text="$t('Cancel')"
  >
    <CometBlockMessage
      block-message-type="error"
      v-if="error"
      role="alert"
    >
      <p>{{ error }}</p>
    </CometBlockMessage>
    <p>
      {{ $t(
        '"%(creationName)s" is in the trash and you cannot make edits.',
        { creationName, interpolation: { escapeValue: false } })
      }}

      {{ $t('If you wish to make edits, move it out of the trash to "My Studio".') }}
    </p>
  </BaseModal>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions } from 'pinia';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import { decodeHTML } from '@/lib/utils';
import * as types from '@/lib/constants/store';
import {
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'ConfirmRestoreCreation',
  components: {
    BaseModal,
    CometBlockMessage,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      error: false,
      restoring: false,
    };
  },
  computed: {
    creationName() {
      return decodeHTML(get(this, 'options.creation.name'));
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useEditorStore, [
      types.RESTORE_CREATION,
    ]),
    async confirmRestore() {
      try {
        this.restoring = true;
        this.error = null;

        await this[types.RESTORE_CREATION]({
          creation: this.options.creation,
          type: this.options.creation.state,
        });

        if (this.options.successCallback) {
          this.options.successCallback();
        }
        this[types.CLOSE_MODAL]({ id: this.id });
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e.message);
      } finally {
        this.restoring = false;
      }
    },
  },
};
</script>
