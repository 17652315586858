<template>
  <BaseModal
    :id="id"
    :is-wide="false"
    :on-confirm="goBackToCreation"
    :on-cancel="continueToPublish"
    :on-header-cancel="goBackToCreation"
    :heading="$t('You Have Inactive Assets')"
    :confirm-text="$t('Go Back to Creation')"
    :cancel-text="$t('Continue to Publish')"
  >
    {{ $t('There are inactive assets that are in this creation.') }}
    {{ $t('Users will not be able to see the asset when placed in service.') }}
    {{ $t('Please go back and replace or make assets active before publishing.') }}
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
} from '@/stores';

export default {
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
      types.OPEN_MODAL,
    ]),
    goBackToCreation() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    continueToPublish() {
      this[types.CLOSE_MODAL]({ id: this.id });
      this[types.OPEN_MODAL]({
        type: 'PublishModal',
      });
    },
  },
};
</script>
