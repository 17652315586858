<!-- eslint-disable max-len -->
<template>
  <div class="do-sticker">
    <div
      class="do-sticker__accent"
    />
    <svg
      class="do-sticker__icon"
      width="80"
      height="85"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_3224_1209)">
        <path
          d="M85.8166 46.807C85.8166 68.3922 68.2756 85.8904 46.6378 85.8904C24.9999 85.8904 7.45898 68.3922 7.45898 46.807C7.45898 25.2219 24.9999 7.72363 46.6378 7.72363C68.2756 7.72363 85.8166 25.2219 85.8166 46.807Z"
          fill="#4E17B5"
          class="do-sticker__background"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46.909 10.4472C26.779 10.4472 10.4605 26.726 10.4605 46.807C10.4605 66.8879 26.779 83.1668 46.909 83.1668C67.039 83.1668 83.3576 66.8879 83.3576 46.807C83.3576 26.726 67.039 10.4472 46.909 10.4472ZM5 46.807C5 23.7176 23.7633 5 46.909 5C70.0547 5 88.818 23.7176 88.818 46.807C88.818 69.8963 70.0547 88.6139 46.909 88.6139C23.7633 88.6139 5 69.8963 5 46.807Z"
        fill="white"
      />
      <path
        d="M25.4529 65.7737C26.3923 65.6798 27.3249 65.5532 28.2437 65.3609C37.8256 63.3557 45.9013 56.1169 44.3012 48.471C42.7012 40.8251 34.5991 38.4447 24.7218 40.5117C23.3436 40.8001 21.9791 41.1542 20.6215 41.541L25.4529 65.7737ZM28.4495 46.3765C28.6464 46.3353 28.8364 46.2613 29.0005 46.2269C33.2008 45.3479 35.8557 47.3955 36.3913 49.9551C36.9682 52.7115 35.0976 56.0486 30.1823 57.1115L28.4495 46.3765ZM72.4204 42.5522C71.0264 35.8908 65.2656 30.6227 57.226 32.3052C49.3504 33.9533 45.9665 40.8611 47.4635 48.0147C48.9194 54.9715 55.5173 59.0026 62.0803 57.6291C68.9058 56.2007 74.0136 50.1653 72.4204 42.5522ZM61.3143 51.35C57.7046 52.1054 55.468 49.7648 54.8911 47.0084C54.1769 43.5956 55.7567 40.8332 58.9397 40.167C62.09 39.5078 64.3335 41.8812 64.8691 44.4408C65.5971 47.9191 64.2348 50.7389 61.3143 51.35Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_dd_3224_1209"
          x="0.474149"
          y="0.738797"
          width="88.8347"
          height="93.3008"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood
            flood-opacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="-4.65656"
            dy="-4.65656"
          />
          <feGaussianBlur stdDeviation="1.16414" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3224_1209"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="-1.16414"
            dy="3.49242"
          />
          <feGaussianBlur stdDeviation="2.32828" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_3224_1209"
            result="effect2_dropShadow_3224_1209"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_3224_1209"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<style lang="stylus">
.do-sticker {
  height: 74px;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  width: 92px;

  &__accent {
    background-image: var(--tei-do-sticker-accent-image);
    background-repeat: no-repeat;
    background-size: cover;
    height: 48px;
    inset-inline-start: -4px;
    position: absolute;
    top: -3px;
    width: 35px;
  }

  &__icon {
    inset-inline-end: -13px;
    position: absolute;
  }

  &__background {
    fill: var(--tei-do-sticker-color);
  }
}
</style>
