<template>
  <BaseModal
    :id="id"
    :is-wide="false"
    hide-footer
    :initial-focus="() => $refs.closeButton"
    class="image-zoom-modal"
    is-fixed
  >
    <button
      ref="closeButton"
      :aria-label="$t('Close')"
      class="nebula-button nebula-button--s nebula-button--icon-only
        modal__header-close-button image-zoom-modal__close-button"
      @click="close"
    >
      <NebulaIcon
        size="m"
        symbol-id="x"
      />
    </button>
    <img
      v-if="validThumbnailUrl"
      class="image-zoom-modal__image"
      :alt="altText"
      :src="validThumbnailUrl"
    >
    <LoadingIndicator
      v-else
      absolute
    />
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import BaseModal from '@/components/modals/BaseModal.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import thumbnailLoader from '@/mixins/thumbnailLoader';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
} from '@/stores';

export default {
  name: 'ImageZoomModal',
  components: {
    BaseModal,
    NebulaIcon,
    LoadingIndicator,
  },
  mixins: [thumbnailLoader],
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      useLargeImageUrls: true, // used by the thumbnail loader
    };
  },
  computed: {
    altText() {
      return this.cachedThumbnail ? this.cachedThumbnail.image_data?.alt_text : null;
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
  },
};
</script>

<style lang="stylus">
// I had some trouble getting an image to be as large as possible in the viewport
// and ALSO align a close button inside it. It seems like it should be simple, but
// it was hard to clamp the size in two directions. If this ends up being buggy
// in the future, it'll probably be easiest to scrap this and set the size/position
// in javascript instead
.image-zoom-modal {
  .comet-modal {
    background: none;
    box-shadow: none;
    max-height: calc(100vh - (2 * 16px));
    max-width: calc(100vw - (8 * 16px));
    width: initial;

    .page-content--contain-within & {
      max-height: calc(100% - (2 * 16px));
      max-width: calc(100% - (8 * 16px));
    }
  }

  .comet-modal__inner.modal__inner {
    background: none;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - (2 * 16px));
    max-width: calc(100vw - (8 * 16px));
    padding: 0;
    width: max-content;

    .page-content--contain-within & {
      max-height: calc(100% - (2 * 16px));
      max-width: calc(100% - (8 * 16px));
    }
  }

  &__close-button {
    // This button is in a fixed container that can be any size, so calculating
    // from the center and then moving it is the easiest way to get it to sit
    // at the edge of the viewport
    inset-inline-end: calc(50% - 50vw);
    position: fixed;
    top: calc(50% - 50vh);
    transform: translate(- $nebula-space-2x, $nebula-space-2x);
    transition: none;

    // If we're using absolute positioning it's best to keep the close button
    // inside the modal itself
    .page-content--contain-within & {
      inset-inline-end: $nebula-space-2x;
      position: absolute;
      top: $nebula-space-2x;
    }

    svg.nebula-icon--m {
      fill: $nebula-color-white;
      transition: fill $nebula-transition-default;
    }

    &:hover svg.nebula-icon--m,
    &:focus svg.nebula-icon--m {
      fill: $nebula-color-interface-blue-400;
    }
  }

  &__image {
    align-self: center;
    border-radius: 8px;
    // Match the box shadow that's usually on the .comet-modal itself
    box-shadow: 0 32px 48px 4px rgba(0, 0, 0, .28),
      0 12px 60px 10px rgba(0, 0, 0, .24),
      0 16px 20px -10px rgba(0, 0, 0, .60);
    flex-shrink: 1;
    max-width: 100%;
    min-height: 0;
    min-width: 0;
  }

  html[dir="rtl"] & {
    color: red;

    &__close-button {
      transform: translate($nebula-space-2x, $nebula-space-2x);
    }
  }
}
</style>
