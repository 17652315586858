<template>
  <BaseModal
    :id="id"
    :is-wide="false"
    :focus-trap-active="false"
    :close-on-backdrop-click="false"
    header-margin="2x"
    hide-footer
    class="tei-checking-answers-modal"
  >
    <div
      role="alert"
      class="tei-checking-answers-modal__content"
    >
      <div class="tei-checking-answers-modal__image" />
      <p class="tei-checking-answers-modal__line-one">
        {{ $t('Checking your answer...') }}
      </p>
      <p class="tei-checking-answers-modal__line-two">
        {{ $t('Please remain on the screen and do not exit') }}
      </p>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';

export default {
  name: 'TEICheckingAnswersModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="stylus">
@import '../teis/styles/tei-fonts';

.tei-checking-answers-modal {

  .comet-modal__inner {
    width: 480px;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    background-image: url('~@/assets/keiki-inspecting-green.png');
    background-repeat: no-repeat;
    height: 228px;
    width: 227px;
  }

  &__line-one {
    tei-mikado-heading-secondary-bold();
    margin: $nebula-space-2x 0 $nebula-space-1x 0;
    text-align: center;
  }

  &__line-two {
    tei-mikado-heading-secondary-regular();
    margin: $nebula-space-2x 0;
    text-align: center;
  }
}
</style>
