<template>
  <BaseModal
    :id="id"
    class="confirm-assign"
    :heading="$t('Are you sure you want to assign?')"
    :initial-focus="() => $refs.continueButton"
    :close-on-backdrop-click="!busy"
  >
    <div class="comet-long-form-text">
      <p>
        {{ $t('Assignments cannot be changed once assigned.') }}
      </p>
    </div>
    <template #footer>
      <div class="comet-modal__footer">
        <div class="comet-modal__button-row confirm-assign__button-row">
          <button
            :disabled="busy"
            class="nebula-button nebula-button--ghost nebula-button--s comet-modal__button
              comet-modal__button--cancel confirm-assign__cancel-button"
            :class="{ 'nebula-button--disabled': busy }"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            ref="continueButton"
            :disabled="busy"
            class="nebula-button nebula-button--fill nebula-button--s comet-modal__button"
            :class="{ 'nebula-button--disabled': busy }"
            @click="onContinue"
          >
            {{ $t('Ok, Continue') }}
          </button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapActions } from 'pinia';
import { get } from 'lodash-es';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
  useToastStore,
} from '@/stores';

export default {
  name: 'ConfirmAssignModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    async onContinue() {
      try {
        this.busy = true;
        await this.options.onContinue();
      } catch (e) {
        const errorMessage = get(e, 'response.data.meta.message', e);
        this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
        this.busy = false;
      }
    },
  },
};
</script>

<style lang="stylus">
.confirm-assign {
  &__button-row.comet-modal__button-row {
    display: flex;
    justify-content: space-between;
  }

  &__cancel-button.comet-button {
    margin-left: 0;
  }
}
</style>
