<template>
  <NebulaButton
    class="tei-view-more-button"
    :class="state && `tei-view-more-button--${state}`"
    type="flat"
    size="s"
    icon-right="caret-right"
    :text="$t('view more')"
    @click="$emit('click', $event)"
    @keyup="$emit('keyup', $event)"
  />
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';

export default {
  components: {
    NebulaButton,
  },
  props: {
    state: {
      type: String,
      default: null, // correct, incorrect, selected
    },
  },
  emits: [
    'click',
    'keyup',
  ],
};
</script>

<style lang="stylus">
@import './styles/tei-fonts';

.tei-view-more-button.nebula-button {
  background-color: var(--tei-view-more-button-color);
  color: $nebula-color-platform-neutral-1000;
  padding: $nebula-space-half $nebula-space-half $nebula-space-half $nebula-space-1x;

  svg {
    fill: $nebula-color-platform-neutral-1000;
  }

  &:hover, &:focus {
    background-color: var(--tei-view-more-button-unselected-hover-color);
    color: var(--tei-view-more-button-unselected-hover-text-color);

    svg {
      fill: var(--tei-view-more-button-unselected-hover-text-color);
    }
  }

  &:active {
    background-color: var(--tei-view-more-button-unselected-active-color);
    color: $nebula-color-white;

    svg {
      fill: $nebula-color-white;
    }
  }

  &.tei-view-more-button--correct {
    &:hover, &:focus {
      background-color: $nebula-color-science-primary-500;
      color: $nebula-color-white;

      svg {
        fill: $nebula-color-white;
      }
    }

    &:active {
      background-color: $nebula-color-science-primary-600;
    }
  }

  &.tei-view-more-button--incorrect {
    &:hover, &:focus {
      background-color: $nebula-color-platform-neutral-900;
      color: $nebula-color-white;

      svg {
        fill: $nebula-color-white;
      }
    }

    &:active {
      background-color: $nebula-color-platform-neutral-1100;
    }
  }

  &.tei-view-more-button--selected {
    &:hover, &:focus {
      background-color: var(--tei-view-more-button-selected-hover-color);
      color: $nebula-color-white;

      svg {
        fill: $nebula-color-white;
      }
    }

    &:active {
      background-color: var(--tei-view-more-button-selected-active-color);
    }
  }

  .nebula-button__text {
    tei-mikado();
    tei-regular();
    margin-inline-end: $nebula-space-half;
  }
}
</style>
