import { findKey } from 'lodash-es';
import { mapState } from 'pinia';
import { getSegmentObjFromExperimentData } from '@/lib/utils';
import {
  useAppStore,
} from '@/stores';

export default {
  computed: {
    ...mapState(useAppStore, [
      'experimentList',
    ]),
  },
  methods: {
    getExperimentData(experimentKey) {
      if (this.experimentList.length) {
        const experiments = this.experimentList;
        const key = findKey(experiments, { variation: { variation_code: experimentKey } });
        if (key) {
          return key ? getSegmentObjFromExperimentData(experiments[key]) : {};
        }
      }
      return {};
    },
  },
};
