import { mapState } from 'pinia';
import {
  EDITOR_CANVAS_WIDTH,
  MODAL_CANVAS_WIDTH,
} from '@/lib/constants';
import {
  useEditorStore,
} from '@/stores';

export default {
  computed: {
    ...mapState(useEditorStore, [
      'currentModalCanvasId',
    ]),
  },
  methods: {
    getVisibleCanvasCenter(mod) {
      const rootEl = this.$root.$el;
      let stage;
      let canvas;
      let canvasWidth;

      if (this.currentModalCanvasId) {
        canvas = rootEl.querySelector('.modal-canvas');
        stage = rootEl.querySelector('.modal-canvas__stage');
        canvasWidth = MODAL_CANVAS_WIDTH;
      } else {
        canvas = rootEl.querySelector('.editor-canvas-carousel__canvas-wrappers');
        stage = rootEl.querySelector('.editor__stage');
        canvasWidth = EDITOR_CANVAS_WIDTH;
      }

      const { scrollTop, scrollLeft } = stage;
      const stageRect = stage.getBoundingClientRect();
      const canvasRect = canvas.getBoundingClientRect();
      const canvasPaddingTop = canvasRect.top - (stageRect.top - scrollTop);
      const canvasPaddingLeft = canvasRect.left - (stageRect.left - scrollLeft);
      const scale = canvasRect.width / canvasWidth;

      const top = canvasRect.top >= stageRect.top
        ? 0 : (scrollTop - canvasPaddingTop) / scale;
      const bottom = canvasRect.bottom <= stageRect.bottom
        ? canvasRect.height / scale
        : (canvasRect.height - (canvasRect.bottom - stageRect.bottom)) / scale;
      const left = canvasRect.left >= stageRect.left
        ? 0 : (scrollLeft - canvasPaddingLeft) / scale;
      const right = canvasRect.right <= stageRect.right
        ? canvasRect.width / scale
        : (canvasRect.width - (canvasRect.right - stageRect.right)) / scale;
      const centerX = left + ((right - left) / 2);
      const centerY = top + ((bottom - top) / 2);

      const y = centerY - (mod.layout.height / 2);
      const x = centerX - (mod.layout.width / 2);
      return { x, y };
    },
  },
};
