<template>
  <AppMessage
    :heading="$t('Moved to Trash')"
    :duration="10000"
  >
    {{ $t(
      '"%(name)s" has been moved to the trash.',
      { name: decodeHTML(options.draft.name), interpolation: { escapeValue: false } })
    }}
  </AppMessage>
</template>

<script>
import { decodeHTML } from '@/lib/utils';
import AppMessage from './AppMessage.vue';

export default {
  components: {
    AppMessage,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      decodeHTML,
    };
  },
};
</script>
