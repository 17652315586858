<template>
  <BaseModal
    :id="id"
    :is-directional="true"
    :is-wide="false"
    :on-confirm="onConfirm"
    :heading="$t('Publish your creation to Discovery Education?')"
    :confirm-text="$t('Yes')"
    :cancel-text="$t('Cancel')"
  />
</template>

<script>
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useAppStore,
  useEditorStore,
  useModalStore,
  useToastStore,
} from '@/stores';

export default {
  name: 'PublishModal',
  components: {
    BaseModal,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState(useEditorStore, [
      'draft',
    ]),
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useEditorStore, [
      types.PUBLISH_DRAFT,
      types.SET_EDITOR_STATUS_MESSAGE,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    ...mapActions(useAppStore, [
      types.UPDATE_LOADING,
    ]),
    async onConfirm() {
      try {
        this[types.CLOSE_MODAL]({ id: this.id });
        const creation = await this[types.PUBLISH_DRAFT]({ draftId: this.draft.id });

        const creationPointerAssetReference = creation.references
          .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

        this.$router.replace({
          name: 'view',
          query: {
            id: creationPointerAssetReference && creationPointerAssetReference.reference_id,
          },
        });
        this[types.SET_EDITOR_STATUS_MESSAGE]({ message: 'publish-confirmation', duration: 15000 });
      } catch (e) {
        const errorMessage = get(e, 'response.data.meta.message', e);
        this[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMessage });
      } finally {
        this[types.UPDATE_LOADING](false);
      }
    },
  },
};
</script>
