<template>
  <div
    class="tei-view-more-container"
    :class="[
      {
        'tei-view-more-container--max-lines': maxLines && !useJsLineClamp,
        'tei-view-more-container--use-js-line-clamp': useJsLineClamp,
      },
      `tei-view-more-container--button-${buttonAlign}`,
    ]"
    tabindex="0"
  >
    <div
      class="tei-view-more-container__content"
      :style="lineClampStyle"
    >
      <div
        ref="content"
        class="tei-view-more-container__content-inner-wrap"
      >
        <slot :content="clampedContent" />
      </div>
    </div>
    <TEIViewMoreButton
      v-if="viewMoreButtonVisible"
      class="tei-view-more-container__view-more"
      :state="state"
      @click.stop="$emit('view-more-clicked', $event)"
      @keyup.enter.stop
    />
  </div>
</template>

<script>
import { endsWith, last } from 'lodash-es';
import TEIViewMoreButton from '@/components/teis/TEIViewMoreButton.vue';

/*
  Max interval for checking for size changes. This will be the interval
  once the CHECK_SIZE_MAX_INTERVAL_TIME is reached. Before this, the interval
  will be a percentage of the max or the CHECK_SIZE_MIN_INTERVAL.
*/
const CHECK_SIZE_MAX_INTERVAL = 1000;
/*
  Min interval to use for checking size changes.
*/
const CHECK_SIZE_MIN_INTERVAL = 50;
/*
  When this amount of time has elapsed, the CHECK_SIZE_MAX_INTERVAL will be used
  from this point forward.
*/
const CHECK_SIZE_MAX_INTERVAL_TIME = 10000;

export default {
  name: 'TEIViewMoreContainer',
  components: {
    TEIViewMoreButton,
  },
  props: {
    maxLines: {
      type: Number,
      default: null,
    },
    buttonAlign: {
      type: String,
      default: 'end', // center, end
    },
    content: {
      type: String,
      default: null,
    },
    allowViewMore: {
      type: Boolean,
      default: true,
    },
    state: {
      type: String,
      default: null, // correct, incorrect, selected
    },
    useJsLineClamp: {
      type: Boolean,
      default: false, // false will use the css line-clamp instead
    },
  },
  emits: [
    'view-more-changed',
    'view-more-clicked',
  ],
  data() {
    return {
      clampedContent: '',
      clampInProgress: false,
      resizeObserver: null,
      viewMore: false,
    };
  },
  mounted() {
    this.onContentChanged();
    this.emitViewMoreChanged();
    /*
      Start a loop to periodically check the size of the view more container.
      This is needed because ResizeObserver doesn't work well with elements that
      have a line-clamp applied since the element will not always resize. Instead,
      we will have a process that monitors the scrollHeight and clientWidth of the content
      element for changes.
    */
    this.startCheckSize();
  },
  beforeUnmount() {
    this.stopCheckSize();
  },
  watch: {
    maxLines() {
      this.startCheckSize();
      this.onContentChanged();
    },
    content() {
      this.onContentChanged();
    },
    allowViewMore() {
      this.startCheckSize();
      this.onContentChanged();
    },
    viewMoreButtonVisible() {
      this.emitViewMoreChanged();
    },
  },
  computed: {
    lineClampStyle() {
      if (this.useJsLineClamp) return null;

      return {
        '-webkit-line-clamp': this.viewMoreButtonVisible && this.maxLines
          ? this.maxLines - 1
          : this.maxLines,
      };
    },
    viewMoreButtonVisible() {
      return this.viewMore && this.allowViewMore;
    },
  },
  methods: {
    getScrollHeight() {
      return this.$refs?.content?.scrollHeight;
    },
    getContentWidth() {
      return this.$refs?.content?.clientWidth;
    },
    checkSize() {
      if (!this.maxLines || !this.allowViewMore) {
        this.stopCheckSize();
        return;
      }

      /*
        Check for size changes very frequently when the component is loaded to prevent
        any visual delays, and then gradually scale back to the CHECK_SIZE_MAX_INTERVAL
        over the duration of CHECK_SIZE_MAX_INTERVAL_TIME.
      */
      const checkTimeElapsed = (new Date()).getTime() - this.checkStartTime;
      let nextTimeout = Math.round((checkTimeElapsed / CHECK_SIZE_MAX_INTERVAL_TIME)
        * CHECK_SIZE_MAX_INTERVAL);
      if (nextTimeout < CHECK_SIZE_MIN_INTERVAL) {
        nextTimeout = CHECK_SIZE_MIN_INTERVAL;
      } else if (nextTimeout > CHECK_SIZE_MAX_INTERVAL) {
        nextTimeout = CHECK_SIZE_MAX_INTERVAL;
      }

      this.checkSizeTimeout = setTimeout(() => {
        const scrollHeight = this.getScrollHeight();
        const contentWidth = this.getContentWidth();
        let contentChanged = false;
        /*
          If the scrollHeight or clientWidth have changed
          for the content element, then call onContentChanged
          to configure the view more button.
        */
        if (scrollHeight !== this.lastScrollHeight) {
          this.lastScrollHeight = scrollHeight;
          contentChanged = true;
        }
        if (contentWidth !== this.lastContentWidth) {
          this.lastContentWidth = contentWidth;
          contentChanged = true;
        }
        if (contentChanged && !this.clampInProgress) {
          this.onContentChanged();
        }
        this.checkSize();
      }, nextTimeout);
    },
    startCheckSize() {
      this.stopCheckSize();
      this.lastScrollHeight = this.getScrollHeight();
      this.lastContentWidth = this.getContentWidth();
      this.checkStartTime = (new Date()).getTime();
      this.checkSize();
    },
    stopCheckSize() {
      if (this.checkSizeTimeout) {
        clearInterval(this.checkSizeTimeout);
        this.checkSizeTimeout = null;
      }
      this.lastScrollHeight = null;
      this.lastContentWidth = null;
      this.checkStartTime = null;
    },
    emitViewMoreChanged() {
      this.$emit('view-more-changed', { viewMore: this.viewMoreButtonVisible });
    },
    onContentChanged(refreshContent = true) {
      if (this.clampInProgress) {
        // If we're in the middle of clamping text, don't interrupt
        return;
      }

      if (!this.allowViewMore || !this.maxLines) {
        this.clampedContent = this.content;
        this.viewMore = false;
        return;
      }

      if (refreshContent) {
        this.clampedContent = this.content;
        this.viewMore = false;
      }

      this.$nextTick(() => {
        const { content } = this.$refs;
        if (!content) return;
        /*
          Get the line height from the root element of the slot's
          content. If the slot contains more than one element, then get
          line height from the parent instead.
        */
        const viewMoreContent = content.children.length === 1
          ? content.children[0]
          : content;
        const style = getComputedStyle(viewMoreContent);
        const lineHeight = parseInt(style.lineHeight, 10);

        if (this.useJsLineClamp) {
          // For the JS line clamp, start looping and clamping
          if ((content.scrollHeight - lineHeight * this.maxLines) > (lineHeight / 2)) {
            this.viewMore = true;
            this.clampInProgress = true;
            this.loopAndClampContent(content, lineHeight);
          }
        } else {
          // For the CSS line clamp, we only need to check the height a single time
          this.viewMore = (content.scrollHeight - lineHeight * this.maxLines) > (lineHeight / 2);
        }
      });
    },
    clampContent(content) {
      if (!content) return '';

      const clampedContentWrapper = document.createElement('div');
      clampedContentWrapper.innerHTML = content;

      // If there's no ellipsis, add it. Otherwise replace the last word/whitespace/character
      // and the trailing ellipsis with a new ellipsis
      const lastTextNode = this.getLastTextNode(clampedContentWrapper);
      if (!endsWith(lastTextNode.textContent, '…')) {
        lastTextNode.textContent += '…';
      } else {
        lastTextNode.textContent = lastTextNode.textContent.replace(/\w+…$|\s+…$|.…$/, '…');
      }

      return clampedContentWrapper.innerHTML;
    },
    loopAndClampContent(content, lineHeight) {
      // Check to see if we've clamped enough text. If we haven't yet, clamp again
      if ((content.scrollHeight - lineHeight * (this.maxLines - 1)) > (lineHeight / 2)) {
        this.clampedContent = this.clampContent(this.clampedContent);
        this.$nextTick(() => {
          this.loopAndClampContent(content, lineHeight);
        });
      } else {
        this.clampInProgress = false;
      }
    },
    getLastTextNode(wrapperDiv) {
      let lastTextNode = wrapperDiv;

      // Step through the HTML content, starting at the end, to
      // find the last element with text content
      while (!(lastTextNode.nodeType === Node.TEXT_NODE && lastTextNode.textContent && lastTextNode.textContent !== '…')) {
        if (lastTextNode.hasChildNodes()) {
          // If the element has children, continue stepping down
          lastTextNode = last(lastTextNode.childNodes);
        } else if (!lastTextNode.textContent || lastTextNode.textContent === '…') {
          // If the element has no text content or is just an ellipsis,
          // delete the node and go back up a level
          const emptyElement = lastTextNode;
          lastTextNode = lastTextNode.parentNode;
          lastTextNode.removeChild(emptyElement);
        } else {
          // The element isn't text, isn't empty, and doesn't have children;
          // Add a new text node and return that so we're not stuck in
          // an infinite loop
          const newTextNode = document.createTextNode('');
          lastTextNode.appendChild(newTextNode);
          lastTextNode = newTextNode;
        }
      }

      return lastTextNode;
    },
  },
};
</script>

<style lang="stylus">
@import './styles/tei-fonts';

.tei-view-more-container {
  display: flex;
  flex-direction: column;
  gap: $nebula-space-half;
  height: auto;
  max-height: 100%;
  width: 100%;

  &--max-lines {
    .tei-view-more-container__content {
      line-clamp();

      flex-shrink: 0;
    }
  }

  &:not(.tei-view-more-container--max-lines):not(.tei-view-more-container--use-js-line-clamp) {
    overflow: auto;
    padding-bottom: $nebula-space-half;
    padding-inline-end: $nebula-space-1x;
  }

  &--use-js-line-clamp {
    .tei-view-more-container__content-inner-wrap {
      display: flex;
    }
  }

  /*
    If we're not using the js line clamp, ensure all elements are inline.
    In Safari, clamped elements must all be inline.
  */
  &:not(.tei-view-more-container--use-js-line-clamp) {
    .tei-view-more-container__content-inner-wrap * {
      display: inline;
    }
  }

  &--button-end {
    .tei-view-more-container__view-more {
      align-self: flex-end;
    }
  }

  &--button-center {
    .tei-view-more-container__view-more {
      align-self: center;
    }
  }
}
</style>
