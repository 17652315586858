/* This mixin expects the component to have an `asset` computed property */

import { mapActions, mapState } from 'pinia';
import {
  useModalStore,
  useQuicklistStore,
  useChannelStore,
  useUserStore,
  useEditorStore,
} from '@/stores';
import { bus } from '@/lib/eventBus';
import {
  EVENT_TYPE_DOCUMENT,
  EVENT_TYPE_BUS,
  EVENT_QUICKLIST_ADD,
  EVENT_QUICKLIST_REMOVE,
  EVENT_CHANNELS_SUBSCRIBE,
  EVENT_CHANNELS_UNSUBSCRIBE,
  EVENT_MY_CONTENT_SAVE,
} from '@/lib/constants';
import * as types from '@/lib/constants/store';

export default {
  emits: ['set-previous-focus'],
  computed: {
    ...mapState(useUserStore, [
      'userIsStudent',
      'region',
      'domains',
    ]),
    ...mapState(useEditorStore, [
      'eventType',
    ]),
    ...mapState(useChannelStore, [
      'channelIds',
    ]),
    ...mapState(useQuicklistStore, [
      'quicklistIds',
    ]),
    actionMenuProps() {
      return {
        asset: this.asset,
        assetType: this.asset?.type?.name,
        backUrl: window.location.href,
        learnUrl: this.domains.appDomain,
        showCreateWithStudio: false,
        userIsStudent: this.userIsStudent,
        quicklistIds: this.quicklistIds,
        subscribedChannelIds: this.channelIds,
      };
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
      types.SET_MY_CONTENT_MODAL_OPTIONS,
    ]),
    ...mapActions(useQuicklistStore, [
      types.ADD_TO_QUICKLIST,
      types.REMOVE_FROM_QUICKLIST,
    ]),
    ...mapActions(useChannelStore, [
      types.SUBSCRIBE_TO_CHANNEL,
      types.UNSUBSCRIBE_FROM_CHANNEL,
    ]),
    onAddToQuicklist() {
      if (this.eventType(EVENT_QUICKLIST_ADD) === EVENT_TYPE_DOCUMENT) {
        // In Core, let Marionette handle the QL
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: EVENT_QUICKLIST_ADD,
            asset: this.asset,
          },
        }));
        return;
      }

      if (this.eventType(EVENT_QUICKLIST_ADD) === EVENT_TYPE_BUS) {
        bus.emit(EVENT_QUICKLIST_ADD, this.asset);
        return;
      }

      this[types.ADD_TO_QUICKLIST](this.asset);
    },
    async onRemoveFromQuicklist() {
      if (this.eventType(EVENT_QUICKLIST_REMOVE) === EVENT_TYPE_DOCUMENT) {
        // In Core, let Marionette handle the QL
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: EVENT_QUICKLIST_REMOVE,
            asset: this.asset,
          },
        }));
        return;
      }

      if (this.eventType(EVENT_QUICKLIST_REMOVE) === EVENT_TYPE_BUS) {
        bus.emit(EVENT_QUICKLIST_REMOVE, this.asset);
        return;
      }

      await this[types.REMOVE_FROM_QUICKLIST](this.asset);
      this.$emit('remove-from-quicklist', this.asset.id);
    },
    openMyContentModal() {
      this.$emit('set-previous-focus', this.$el.querySelector('.nebula-card__action-menu .nebula-button'));

      if (this.eventType(EVENT_MY_CONTENT_SAVE) === EVENT_TYPE_BUS) {
        bus.emit(EVENT_MY_CONTENT_SAVE, {
          assetId: this.asset.id,
          title: this.asset.title || this.asset.name,
        });
        return;
      }

      this[types.SET_MY_CONTENT_MODAL_OPTIONS]({
        asset: {
          actions: [],
          selectedAssetId: this.asset.id,
          selectedAssetTitle: this.asset.title || this.asset.name,
          sharingEnabled: true,
        },
        config: {
          hideDistrictTab: this.region === 'UK', // see DEX-2646
          isActive: true,
          isStudent: this.userIsStudent,
          modalType: 'save',
        },
      });
    },
    openShareModal() {
      this.$emit('set-previous-focus', this.$el.querySelector('.nebula-card__action-menu .nebula-button'));

      this[types.OPEN_MODAL]({
        type: 'AssetShareModal',
        id: 'AssetShareModal',
        assetUrl: `${this.domains.appDomain}/player/${this.asset.id}`,
        assetGuid: this.asset.id,
        headline: this.asset.name || '',
        assetDescription: this.asset.description || '',
      });
    },
    subscribe() {
      // In Core, let Marionette handle My Channels
      if (this.eventType(EVENT_CHANNELS_SUBSCRIBE) === EVENT_TYPE_DOCUMENT) {
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'subscribe',
            asset: this.asset,
          },
        }));
        return;
      }

      if (this.eventType(EVENT_CHANNELS_SUBSCRIBE) === EVENT_TYPE_BUS) {
        bus.emit(EVENT_CHANNELS_SUBSCRIBE, this.asset);
        return;
      }

      this[types.SUBSCRIBE_TO_CHANNEL](this.asset);
    },
    unsubscribe() {
      // In Core, let Marionette handle My Channels
      if (this.eventType(EVENT_CHANNELS_UNSUBSCRIBE) === EVENT_TYPE_DOCUMENT) {
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'unsubscribe',
            asset: this.asset,
          },
        }));
        return;
      }

      if (this.eventType(EVENT_CHANNELS_UNSUBSCRIBE) === EVENT_TYPE_BUS) {
        bus.emit(EVENT_CHANNELS_UNSUBSCRIBE, this.asset);
        return;
      }

      this[types.UNSUBSCRIBE_FROM_CHANNEL](this.asset);
    },
  },
};
