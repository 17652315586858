<template>
  <AppMessage
    :heading="heading"
    :duration="5000"
    type="error"
  >
    {{ options.message }}
  </AppMessage>
</template>

<script>
import { decodeHTML } from '@/lib/utils';
import AppMessage from './AppMessage.vue';

export default {
  name: 'ChannelSubscribeErrorMessage',
  components: {
    AppMessage,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      decodeHTML,
    };
  },
  computed: {
    heading() {
      return this.options.op === 'add'
        ? this.$t('Unable to Add')
        : this.$t('Unable to Remove');
    },
  },
};
</script>
