import { v4 as uuid } from 'uuid';
import { mapActions, mapState } from 'pinia';
import { isGuid } from '@/lib/utils';
import addModule from '@/mixins/addModule';
import {
  useAssetStore,
  useEditorStore,
  useModalStore,
  useToastStore,
  useUserStore,
} from '@/stores';
import * as types from '@/lib/constants/store';
import * as moduleDefaults from '@/lib/constants/moduleDefaults';

export default {
  mixins: [addModule],
  computed: {
    ...mapState(useEditorStore, [
      'activeCanvasName',
      'currentModalCanvasId',
      'domains',
      'draft',
      'draftType',
      'editorViewModePreview',
    ]),
    ...mapState(useUserStore, [
      'isDeUser',
    ]),
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.ADD_MODAL_CANVAS,
      types.ADD_PAGE,
      types.PASTE_MODULE_FROM_CLIPBOARD,
      types.PASTE_MODULE_FROM_GUID,
      types.PASTE_MODULE,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    ...mapActions(useAssetStore, [
      types.CACHE_ASSETS_AND_UPLOADS,
    ]),
    async onPaste(e) {
      // If we're in preview mode, exit
      if (this.editorViewModePreview) return;

      // If we're editing text, exit. Sometimes if we're editing an empty
      // text module, the target isn't really in the DOM inside that module,
      // but it'll return an empty parent node.
      if (e.target.closest('input[type=text], textarea, .text-editor__content--focused')
        || !e.target.parentNode) {
        return;
      }

      e.preventDefault();

      const pasteCanvas = this.currentModalCanvasId
        ? 'modalModules'
        : (this.activeCanvasName || 'modules');

      // If there are files on the clipboard, upload those rather than reading
      // clipboard text
      if (e.clipboardData.files.length) {
        if (this.isDeUser) {
          this[types.OPEN_MODAL]({
            type: 'EditorialUploadInfo',
            allowMultiple: true,
            canvasName: pasteCanvas,
            initFiles: [...e.clipboardData.files],
          });
        } else {
          this[types.OPEN_MODAL]({
            type: 'MyContentUploadModal',
            allowMultiple: true,
            baseURL: this.domains.deDomain,
            initFiles: [...e.clipboardData.files],
            onAssetsUploaded: this.addModulesFromMyContent,
          });
        }
        return;
      }

      const pastedText = e.clipboardData.getData('text/plain');
      if (pastedText) {
        // First check if we've pasted a guid
        if (isGuid(pastedText)) {
          const assetGuid = pastedText;

          if (pasteCanvas === 'notes') {
            this[types.PASTE_MODULE_FROM_GUID]({
              guid: assetGuid,
              canvasName: pasteCanvas,
            });
          } else {
            // grab the asset data
            const apiResults = await this.$deApi.get(`/assets/${assetGuid}`);

            if (apiResults.status === 200 && apiResults.data.asset) {
              const { asset } = apiResults.data;

              // create the module
              const module = moduleDefaults.assetModuleDefault(true, asset);
              module.options.asset_id = asset.id;

              // grab the default location
              const position = this.getVisibleCanvasCenter(module);

              this[types.PASTE_MODULE]({
                module,
                position,
                canvasName: pasteCanvas,
              });
            }
          }
          return;
        }

        // Check for invalid guid with special characters and incorrect guid length
        const isGuidPattern = pastedText.split('-');
        const patternBreaks = isGuidPattern.length === 5;

        const specialChars = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]+/;
        const specialCharsCount = pastedText.split(specialChars).length === 5;

        if ((patternBreaks && isGuidPattern.at(-1).length !== 12)
         || (specialCharsCount && pastedText.length === 36)) {
          this[types.SET_STUDIO_TOAST]({
            type: 'error',
            message: this.$t('Paste failed: invalid asset'),
            position: 'top',
          });
          return;
        }

        // Otherwise check if we've pasted a page
        try {
          const copyData = JSON.parse(pastedText);
          if (copyData.copyType === 'pages') {
            if (copyData.draftType === 'board' && this.draftType !== 'board') {
              this[types.SET_STUDIO_TOAST]({
                type: 'error',
                message: this.$t('A board page cannot be pasted into a slideshow.'),
                position: 'top',
              });
              return;
            }

            if (copyData.draftType !== 'board' && this.draftType === 'board') {
              this[types.SET_STUDIO_TOAST]({
                type: 'error',
                message: this.$t('A slide cannot be pasted into a board.'),
                position: 'top',
              });
              return;
            }

            const copyDataContainsFullSlideAssets = copyData.pages
              .some((page) => page.options && page.options.asset_id);

            if (this.draftType !== 'lesson' && copyDataContainsFullSlideAssets) {
              this[types.SET_STUDIO_TOAST]({
                type: 'error',
                message: this.$t('Full-slide assets can only be used in lessons.'),
                position: 'top',
              });
              return;
            }

            // Modal ids have to be updated since they are used as assets.
            // Since modules can reference these ids, they need to be updated
            // together
            const modalIdMap = {};

            // If we're copying and pasting within the same draft, we don't need
            // to update modal ids since it would create unnecessary duplicates
            const updateModalIds = copyData.draftId !== this.draft.id;

            copyData.pages.forEach((page) => {
              if (updateModalIds) {
                [...page.modules, ...(page.notes || [])].forEach((mod) => {
                  /* eslint-disable no-param-reassign */
                  if (mod.options?.action === 'window' && mod.options.target_page) {
                    if (modalIdMap[mod.options.target_page]) {
                      // If we already have a new id for this modal, use that
                      mod.options.target_page = modalIdMap[mod.options.target_page];
                    } else {
                      // Otherwise create a new id
                      const newModalId = uuid();
                      modalIdMap[mod.options.target_page] = newModalId;
                      mod.options.target_page = newModalId;
                    }
                  }
                  /* eslint-enable no-param-reassign */
                });
              }

              // The ADD_PAGE action regenerates the page id and module ids, so we
              // don't have to worry about that here
              this[types.ADD_PAGE]({
                page,
                keepTheme: true,
              });
            });
            // We only need to add modals if we're pasting across drafts. Otherwise
            // the modals will already exist
            if (updateModalIds) {
              (copyData.modals || []).forEach((modal) => {
                if (!modal) return;

                const updatedModal = {
                  ...modal,
                  id: modalIdMap[modal.id] || uuid(),
                };

                this[types.ADD_MODAL_CANVAS](updatedModal);
              });
            }

            // Refresh our asset/upload cache in case there were any new
            // ones in the pages or modals we've pasted
            this[types.CACHE_ASSETS_AND_UPLOADS]();

            // These l10n terms don't have interpolation but they will still use
            // a plural term if we pass in `count`
            this[types.SET_STUDIO_TOAST]({
              type: 'success',
              position: 'top',
              message: this.draftType === 'board'
                ? this.$t('Page pasted.', { count: copyData.pages.length })
                : this.$t('Slide pasted.', { count: copyData.pages.length }),
            });
          } else if (copyData.copyType === 'modules') {
            // Modal ids have to be updated since they are used as assets.
            // Since modules can reference these ids, they need to be updated
            // together
            const modalIdMap = {};

            // If we're copying and pasting within the same draft, we don't need
            // to update modal ids since it would create unnecessary duplicates
            const updateModalIds = copyData.draftId !== this.draft.id;

            copyData.modules.forEach((mod) => {
              if (updateModalIds) {
                /* eslint-disable no-param-reassign */
                if (mod.options?.action === 'window' && mod.options.target_page) {
                  if (modalIdMap[mod.options.target_page]) {
                    // If we already have a new id for this modal, use that
                    mod.options.target_page = modalIdMap[mod.options.target_page];
                  } else {
                    // Otherwise create a new id
                    const newModalId = uuid();
                    modalIdMap[mod.options.target_page] = newModalId;
                    mod.options.target_page = newModalId;
                  }
                }
                /* eslint-enable no-param-reassign */
              }

              this[types.PASTE_MODULE_FROM_CLIPBOARD]({
                canvasName: pasteCanvas,
                clipboardData: {
                  canvasName: copyData.canvasName,
                  module: mod,
                  gridIsDisabled: copyData.gridIsDisabled,
                },
              });
            });

            // We only need to add modals if we're pasting across drafts. Otherwise
            // the modals will already exist
            if (updateModalIds) {
              (copyData.modals || []).forEach((modal) => {
                if (!modal) return;

                const updatedModal = {
                  ...modal,
                  id: modalIdMap[modal.id] || uuid(),
                };

                this[types.ADD_MODAL_CANVAS](updatedModal);
              });
            }

            // Refresh our asset/upload cache in case there were any new assets
            // in modals we pasted
            this[types.CACHE_ASSETS_AND_UPLOADS]();
          }
        } catch (error) {
          // If we get an exception here it means that the user tried to paste
          // something that was not valid JSON; ignore it.
        }
      } else if (!this.isModuleClipboardEmpty) {
        // If there's nothing in the device clipboard but we have a module copied,
        // paste that
        this[types.PASTE_MODULE_FROM_CLIPBOARD]({
          canvasName: pasteCanvas,
        });
      }
    },
  },
};
