<template>
  <div class="share-ui">
    <div :class="{ 'share-ui__contain-within': containWithin }">
      <CometBlockMessage
        role="alert"
        block-message-type="error"
        v-if="error"
      >
        <p>{{ error }}</p>
      </CometBlockMessage>
      <LoadingIndicator
        :translucent="true"
        :absolute="containWithin"
        :text="loadingText"
        v-if="loading"
      />
      <div class="share-contents">
        <InviteForm
          :shares="localData.shares"
          ref="inviteForm"
          @add-invitee="stageInvitee"
        />

        <ul
          class="user-shares-list"
          @scroll="closePermissionsDropdowns"
        >
          <li
            class="user-shares-list__share user-shares-list__share--static
              user-shares-list__share--owner"
          >
            <span class="share-avatar share-avatar--owner">
              {{ toInitial(localData.owner.firstName) }}{{ toInitial(localData.owner.lastName) }}
            </span>
            <span class="user-shares-list__name">
              {{ localData.owner.firstName }} {{ localData.owner.lastName }}
            </span>
            <span>
              {{ $t('Owner') }}
            </span>
          </li>
          <template
            v-for="domain in ['user', 'classroom', 'group']"
            :key="domain"
          >
            <li
              v-for="(share, i) in localData.shares[domain]"
              :key="share.owner_guid"
              class="user-shares-list__share"
            >
              <span
                class="share-avatar"
                :class="`share-avatar--${domain}`"
              >
                <template v-if="domain === 'user'">
                  {{ toInitial(share.first_name) }}{{ toInitial(share.last_name) }}
                </template>
                <template v-else>
                  {{ toInitial(share.name) }}
                </template>
              </span>
              <span class="user-shares-list__name">
                <template v-if="domain === 'user'">
                  {{ share.first_name }} {{ share.last_name }}
                </template>
                <template v-else>
                  {{ share.name }}
                </template>
              </span>
              <PermissionsDropdown
                ref="sharePermissionsDropdowns"
                :selected-id="share.type"
                @select-share-permissions="updateSharePermissions(domain, i, $event)"
              />
              <button
                class="comet-button comet-button--flat comet-button--dark
                  comet-button--icon-only user-shares-list__remove-button"
                :aria-label="$t('Remove Share')"
                @click="removeShare(domain, i)"
              >
                <NebulaIcon
                  symbol-id="trash"
                  class="comet-button__icon comet-button__icon--right user-shares-list__remove-icon"
                  size="s"
                />
              </button>
            </li>
          </template>
        </ul>
        <div class="copy-creation">
          <div class="copy-creation-link">
            <transition
              mode="out-in"
              name="copy-creation__transition"
              enter-from-class="copy-creation__transition--enter-from"
              enter-active-class="copy-creation__transition--enter-active"
              leave-active-class="copy-creation__transition--leave-active"
              leave-to-class="copy-creation__transition--leave-to"
            >
              <div
                role="alert"
                class="copy-creation__copy-link-button copy-creation__confirm"
                v-if="showCopyConfirmMessage"
              >
                <NebulaIcon
                  symbol-id="circle-check--filled"
                  size="m"
                />  {{ $t('Copied!') }}
              </div>
              <button
                v-else
                class="copy-creation__copy-link-button"
                @click="copyLink"
              >
                <NebulaIcon
                  symbol-id="link"
                  size="m"
                />
                {{ $t('Copy Link') }}
              </button>
            </transition>
            <input
              class="copy-creation__url"
              :value="shareUrl"
              type="text"
              ref="linkInput"
              :disabled="linkInputDisabled"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, throttle, cloneDeep } from 'lodash-es';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { mapActions } from 'pinia';
import InviteForm from '@/components/share/InviteForm.vue';
import PermissionsDropdown from '@/components/share/PermissionsDropdown.vue';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import { sharingTypes } from '@/lib/constants';
import { getDomains } from '@/lib/api';
import { toInitial } from '@/lib/utils';
import * as types from '@/lib/constants/store';
import {
  useEditorStore,
} from '@/stores';

// get domains once
const domains = getDomains();

export default {
  name: 'ShareUI',
  components: {
    InviteForm,
    PermissionsDropdown,
    CometBlockMessage,
    LoadingIndicator,
    NebulaIcon,
  },
  props: {
    /**
     * Optionally pass a draft/creation id (as id) OR full draft/creation payload (as data).
     * if an id is passed, the draft payload will be fetched
     */
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'draft',
    },
    data: {
      type: Object,
      default: null,
    },
    containWithin: {
      type: Boolean,
      default: true,
    },
    signinUrl: {
      type: String, // optional signin url
      default: null,
    },
  },
  emits: [
    'error',
    'shareAdded',
    'shareRemoved',
    'shareUpdated',
    'submitAttempt',
    'submitted',
  ],
  computed: {
    shareUrl() {
      if (!this.localData || !this.localData.references) return null;

      const draftPointerReference = this.localData.references
        .find((ref) => ref.reference_type === 'asset' && ref.reference_subtype === 'head');

      if (!draftPointerReference) return null;

      const url = `${domains.studioDomain}/view?id=${draftPointerReference.reference_id}&shared=true`;

      if (this.signinUrl) {
        return `${this.signinUrl}?next=${encodeURIComponent(url)}`;
      }
      return url;
    },
  },
  data() {
    return {
      disableConfirmButton: false,
      publicAccessType: null,
      sharingTypes,
      loading: true,
      loadingText: '',
      showCopyConfirmMessage: false,
      error: null,
      localData: {
        id: null,
        shares: {},
        owner: {},
        type: null,
      },
      linkInputDisabled: true,
    };
  },
  /**
   * Watch for potentially collaborative changes to shares
   */
  watch: {
    data() {
      this.initialize();
    },
    id() {
      this.initialize();
    },
  },
  async mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.UPDATE_SHARE_ID,
    ]),
    async initialize() {
      if (!this.id && !this.data) { // must pass an id or data
        const error = this.$t('Missing Data or Id');
        this.error = error;
        this.$emit('error', {
          type: 'fatal',
          message: error,
        });
      } else if (this.id && !this.data) { // id?
        await this.getSharesFromId();
        this.loading = false;
      } else if (this.data) {
        // no need to load since we don't need to fetch a draft
        this.updateData(this.data);
        this.loading = false;
      }
    },
    toInitial,
    async updateSharing(shares) {
      // The API expects a trimmed version of each share, so other data must be stripped
      const stripShare = (share) => ({
        owner_guid: share.owner_guid,
        type: share.type,
      });
      const strippedShares = {
        classroom: shares.classroom.map(stripShare),
        group: shares.group.map(stripShare),
        user: shares.user.map(stripShare),
      };

      // Send the API request
      const patchList = [
        {
          op: 'replace',
          path: '/shares',
          value: strippedShares,
        },
      ];
      const result = await this.$api.patch(`/${this.type}s/${this.localData.id}/shares`, patchList);

      if (result.status !== 200) {
        throw result;
      }

      this.localData.shares = shares;

      // return share id
      return result.data.draft.share_id;
    },
    async getSharesFromId() {
      try {
        const path = `/drafts/${this.id}`;

        // get the data
        const result = await this.$api.get(path,
          {
            headers: { 'X-Fields': 'id, options, shares, owner_first_name, owner_last_name, references' },
          });

        if (get(result, 'status') === 200) {
          const { draft } = result.data;
          this.updateData(draft);
        }
      } catch (error) {
        this.$emit('error', error);
        this.error = error;
      }
    },
    updateData(data) {
      this.localData = {
        id: data.id,
        type: data.options.type,
        shares: cloneDeep(data.shares),
        owner: {
          firstName: data.owner_first_name,
          lastName: data.owner_last_name,
        },
        references: data.references,
      };
    },
    continueEditing() {
      this.isDirty = true;
      const focusTrap = get(this.$refs, 'baseModal.$refs.focusTrap');
      if (focusTrap) focusTrap.activate();
    },
    canCancel() {
      if (this.isDirty) {
        // show the prompt
        this.error = this.$t('Your sharing changes will be lost if you don’t save them.');
        return false;
      }
      return true;
    },
    async copyLink() {
      await this.submit(true);
    },
    closePermissionsDropdowns: throttle(function unthrottledCloseDropdowns() {
      // Dropdowns are manually positioned since they have to overflow
      // their scroll container. Rather than repositioning them when the user
      // scrolls (which would be jumpy or expensive), just close them
      this.$refs.sharePermissionsDropdowns.forEach((dd) => {
        dd.closeDropdown();
      });
    }, 300),
    async submit(copyLink = false) {
      this.loading = true;
      let source;

      if (copyLink) {
        this.loadingText = this.$t('Creating Shareable Link');
        source = 'copyLink';
      } else {
        source = 'submit';
        let text = this.localData.type === 'board'
          ? this.$t('Sharing your Board')
          : this.$t('Sharing your Slideshow');

        if (this.localData.type === 'lesson') {
          text = this.$t('Sharing your Lesson');
        }

        this.loadingText = text;
      }

      // clear errors on submit
      this.error = null;

      // send attempted event
      this.$emit('submitAttempt', { source });

      // helper func to handle post submission activities
      const postSubmit = (success, submitSource, shareId) => {
        if (success && submitSource === 'copyLink') {
          // select text
          this.linkInputDisabled = false;
          this.$nextTick(() => {
            this.$refs.linkInput.select();
            // copy
            document.execCommand('copy');
            this.showCopied();
            this.linkInputDisabled = true;
            if (document.activeElement === this.$refs.linkInput) {
              this.$refs.linkInput.blur();
            }
          });
        }

        this.$emit('submitted', {
          success,
          source,
          shares: this.localData.shares,
          shareId,
        });

        this[types.UPDATE_SHARE_ID](shareId);

        this.loading = false;
      };

      let success = true;
      let shareId = null;

      this.disableConfirmButton = true;
      try {
        // Update shares and public access
        shareId = await this.updateSharing(this.localData.shares);

        this.isDirty = false;
        success = true;
      } catch (e) {
        this.error = e;
        this.$emit('error', { type: 'fatal', data: e });
        success = false;
      }

      postSubmit(success, source, shareId);
      return true;
    },
    removeShare(domain, index) {
      // For example, remove a specific 'user' share
      this.localData.shares[domain].splice(index, 1);
      this.isDirty = true;

      this.$emit('shareRemoved', { domain, index });
    },
    stageInvitee(invitee) {
      // Integrate the new invitee into their respective domain
      this.localData.shares[invitee.domain].push({
        first_name: invitee.first_name,
        last_name: invitee.last_name,
        name: invitee.name, // for classrooms and groups
        owner_guid: invitee.id,
        type: invitee.type,
      });
      this.isDirty = true;

      this.$emit('shareAdded', { domain: invitee.domain, invitee });
    },
    updateSharePermissions(domain, index, shareType) {
      const originalType = this.localData.shares[domain][index].type;

      // For example, change the 'user' share at index 0 to 'admin'
      this.localData.shares[domain][index].type = shareType;
      this.isDirty = true;

      this.$emit('shareUpdated', {
        domain,
        invitee: this.localData.shares[domain][index],
        originalType,
        newType: shareType,
      });
    },
    showCopied() {
      this.showCopyConfirmMessage = true;
      setTimeout(() => {
        this.showCopyConfirmMessage = false;
      }, 2000);
    },
  },
};
</script>

<style lang="stylus">
.share-ui {
  height: 100%;
  width: 100%;

  &__contain-within {
    height: 100%
    position: relative;
  }
}

.user-shares-list {
  flex-shrink: 1;
  list-style-type: none;
  margin: 0 0 $nebula-space-4x 0;
  overflow: auto;
  padding: 0;

  &__share { // .user-shares-list__share
    align-items: center;
    border-bottom: $comet-border-hairline-default;
    display: flex;
    padding-inline-start: $nebula-space-2x;

    &--owner { // user-shares-list__share--owner
      border-color: $comet-color-neutral-60;
    }

    &--static { // .user-shares-list__share--static
      padding-inline-end: $nebula-space-2x;
    }
  }

  &__name { // .user-shares-list__name
    flex-grow: 1;
    padding-block: $nebula-space-2x;
    padding-inline: $nebula-space-1x $nebula-space-2x;
  }

  &__remove-button.comet-button { // .user-shares-list__remove-button.comet-button
    align-items: center;
    display: flex;
    height: 42px; // match default comet button
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  &__remove-icon.comet-button__icon { // .user-shares-list__remove-icon.comet-button__icon
    height: $nebula-space-2x;
    width: $nebula-space-2x;
  }

  html[dir="rtl"] & {
    &__remove-button.comet-button {
      margin: 0;
    }
  }
}

.share-avatar {
  background-color: $comet-color-lime-30; // after ORION-3636 this will be the student color
  border-radius: 50%;
  color: $nebula-color-white;
  flex-shrink: 0;
  height: $nebula-space-4x;
  line-height: $nebula-space-4x;
  text-align: center;
  width: $nebula-space-4x;

  &--owner {
    background-color: $comet-color-midnight-50;
  }

  &--user {
    background-color: $comet-color-plum-50;
  }

  &--classroom, &--group {
    background-color: $comet-color-orchid-50;
  }
}

.sharing-modal-buttons {
  // .sharing-modal-buttons__button-row.comet-modal__button-row
  &__button-row.comet-modal__button-row {
    justify-content: space-between;
  }

  &__cancel.comet-button { // .sharing-modal-buttons__cancel.comet-button
    margin-inline-start: 0;
  }

  &__last-published-message { // .sharing-modal-buttons__last-published-message
    flex-grow: 1;
    padding-inline-end: $nebula-space-2x;
    text-align: end;
  }
}

.copy-creation {
  &__url {
    position: fixed;
    right: -10000px;
  }

  &__transition--enter-active {
    transition: opacity $nebula-transition-default;
  }
  &__transition--leave-active {
    opacity: 0;
  }
  &__transition--enter-from,
  &__transition--leave-to {
    opacity: 0;
  }

  &__copy-link-button {
    align-items: center;
    background: none;
    border: 0;
    border-radius: $nebula-border-radius-button-default;
    color: $nebula-color-interactive-blue-500;
    display: flex;
    font-size: $nebula-font-size-body-1;
    justify-content: flex-start;
    line-height: 1.4;
    margin: 0;
    padding: $nebula-space-2x $nebula-space-3x;
    width: 250px;

    svg {
      fill: $nebula-color-interactive-blue-500;
      flex-shrink: 0;
      height: $comet-size-icon-m;
      margin-inline-end: $nebula-space-1x;
      width: $comet-size-icon-m;
    }
    &:hover,
    &:focus {
      background-color: rgba($nebula-color-interactive-blue-500, 0.25);
    }
  }

  &__confirm {
    font-weight: bold;
    color: $nebula-color-interactive-blue-500;

    &:hover{
      background-color: transparent;
    }

    svg {
      fill: $nebula-color-success;
    }
  }
}
.unsaved-sharing-buttons {
  &__cancel-button.comet-button {
    margin-block: 0;
    margin-inline: 0 auto;
  }
}
</style>
